// store.js
import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router'
import { EventBus } from '@/plugins/event-bus.js';
import axiosInstance from '@/plugins/axios.js';

var token = ''
if(localStorage.userData) {
  token = JSON.parse(localStorage.userData).access_token
}

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    cart: {},
    userCart: [],
    city_list: [],
    district_list: [],
    selected_city: {},
    selected_district: {},
    car_brands: [],
    car_years: [],
    car_models: [],
    car_versions: [],
    size_brands: [],
    size_seasons: [],
    size_widths: [],
    size_heights: [],
    size_radiuses: [],
    user_cars: [],
    default_user_car: {},
    megaSearchSteps: {
      car: 0,
      size: 0
    },
    megaSearchData: {
      /*
      brand: {
        name: '',
        car_brand_id: ''
      },
      year: '',
      model: {
        car_model_id: '',
        car_model_name: ''
      },
      version: {
        version_name_1: '',
        version_name_2: '',
      }
      */
    },
    megaSearchDataBySize: {

      brands: [],
      seasons: [],
      width: '',
      height: '',
      radius: '',
      runflat: 0

    },
    loading:true
  },
  mutations: {
    async setDefaultCar(state, data) {
      state.default_user_car = data
    },
    async megaSearchSetBrand(state, data) {
      state.megaSearchData.brand = data
    },
    async megaSearchSetBrandForSize(state, data) {
      state.megaSearchDataBySize.brands = data
    },
    async megaSearchSetSeasonForSize(state, data) {
      state.megaSearchDataBySize.seasons = data
    },
    async megaSearchSetWidthForSize(state, data) {
      state.megaSearchDataBySize.width = data
    },
    async megaSearchSetHeightForSize(state, data) {
      state.megaSearchDataBySize.height = data
    },
    async megaSearchSetRadiusForSize(state, data) {
      state.megaSearchDataBySize.radius = data
    },
    async megaSearchSetRunflatForSize(state, data) {
      state.megaSearchDataBySize.runflat = data
    },
    async megaSearchSetYear(state, data) {
      state.megaSearchData.year = data
    },
    async megaSearchSetModel(state, data) {
      state.megaSearchData.model = data
    },
    async megaSearchSetVersion(state, data) {
      state.megaSearchData.version = data
    },
    async increaseMegaSearchCarStep(state) {
      state.megaSearchSteps.car++
      if(state.megaSearchSteps.car > 3) {
        // router.push('/lastik/urunler' )
        router.push({ path: '/lastik/urunler' })
      }
    },
    async decreaseMegaSearchCarStep(state) {
      state.megaSearchSteps.car--
    },
    async increaseMegaSearchSizeStep(state) {
      state.megaSearchSteps.size++
      if(state.megaSearchSteps.size > 5) {
        // router.push('/lastik/urunler' )
        router.push({ path: '/lastik/urunler' })
      }
    },
    async decreaseMegaSearchSizeStep(state) {
      state.megaSearchSteps.size--
    },
    async getUserCars(state) {
      var user_cars = await axiosInstance.get(`/web/main/profile/cars`, {
      })
      state.user_cars = user_cars.data.responseData.userCars.items
    },
    async getCarYears(state) {},
    async addCart(state, data) {
      if(token.length>0) {
        var cart = await axiosInstance.post(`/web/main/c-tire/shopping-cart`, {
          quantity: data.quantity,
          price_id: data.price_id,
          item_id: data.item_id,
          branch_id: data.branch_id // 814
        })
        if(cart.data.status) {
          Vue.swal({
            icon: 'success',
            timer: 1500,
            timerProgressBar: true,
            showConfirmButton: false,
            text: 'Ürün sepetinize eklendi.',
          })
          EventBus.$emit('custom-event', cart.data);
          EventBus.$emit('open-cart-sidebar', cart.data);
        } else if(cart.data.status || !cart.data) {
          Vue.swal({
            icon: 'error',
            timer: 1500,
            timerProgressBar: true,
            showConfirmButton: false,
            text: 'Beklenmeyen bir hata oluştu.',
          })
        }
      }
    },
    async getCities(state) {
      var city_list = await axiosInstance.get(`/mobile/main/regions/cities`, {
      })
      state.city_list = city_list.data.responseData.cities
    },
    async getDistricts(state, city_id) {
      var district_list = await axiosInstance.get(`/mobile/main/regions/districts?city_id=${city_id}`, {
      })
      state.district_list = district_list.data.responseData.districts
    },
    async selectCity(state, city) {
      if(city) {
        state.selected_city = city
      } else {
        state.selected_city = {}
      }
    },
    async getBrands(state) {
      var car_brands = await axiosInstance.get(`/web/main/cars/brands`, {
      })
      state.car_brands = car_brands.data.responseData.carBrands
    },
    async getSizeBrands(state) {
      var size_brands = await axiosInstance.get(`/web/main/home-page/brands?is_corporate=1`, {
      })
      state.size_brands = size_brands.data.responseData.brands
    },
    async getSizeSeasons(state) {
      var size_seasons = await axiosInstance.get(`/web/main/tire/seasons?app_type_code=tire`, {
      })
      state.size_seasons = size_seasons.data.responseData.seasons
    },
    async getSizeWidths(state) {
      var size_widths = await axiosInstance.get(`/web/main/tire/widths?app_type_code=tire`, {
      })
      state.size_widths = size_widths.data.responseData.widths
    },
    async getSizeHeights(state) {
      var size_heights = await axiosInstance.get(`/web/main/tire/heights?app_type_code=tire`, {
      })
      state.size_heights = size_heights.data.responseData.heights
    },
    async getSizeRadiuses(state) {
      var size_radiuses = await axiosInstance.get(`/web/main/tire/radiuses?app_type_code=tire`, {
      })
      state.size_radiuses = size_radiuses.data.responseData.radiuses
    },
    async getYears(state) {
      var car_years = await axiosInstance.get(`/web/main/cars/years?car_brand_id=${state.megaSearchData.brand.car_brand_id}`, {
      })
      state.car_years = car_years.data.responseData.carYears
    },
    async getModels(state) {
      var car_models = await axiosInstance.get(`/web/main/cars/models?car_brand_id=${state.megaSearchData.brand.car_brand_id}&year=${state.megaSearchData.year}`, {
      })
      state.car_models = car_models.data.responseData.carModels
    },
    async getVersions(state) {
      var car_versions = await axiosInstance.get(`/web/main/cars/versions?car_brand_id=${state.megaSearchData.brand.car_brand_id}&year=${state.megaSearchData.year}&car_model_id=${state.megaSearchData.model.car_model_id}`, {
      })
      state.car_versions = car_versions.data.responseData
    },
    async getUserCart(state) {
      if(token.length>0) {
        try {
          this.state.loading = true
          var cart = await axiosInstance.get(`/web/main/c-tire/shopping-cart`, {
          })
          state.userCart = cart.data.responseData
          this.state.loading = false
        }
        catch {
          router.push({ path: '/giris-yap' })
        }
      }
    },
    async deleteCard({commit, state, dispatch, rootState}, data) {
      try {
        var cart = await axiosInstance.post(`/web/main/c-tire/shopping-cart/${data}`, {
          _method: 'delete'
        })
        if(cart.data.status) {
          Vue.swal({
            icon: 'success',
            timer: 1500,
            timerProgressBar: true,
            showConfirmButton: false,

            text: cart.data.statusMessage,
          })
        }
        return false
      } catch (error) {
        console.log(error)
        return false
      }
    },
  },
  actions: {
    addCart({ commit }) {
      commit('addCart');
    },
    listCities({ commit }, data) {
      commit('getCities', data);
    },
    listDistricts({ commit }, data) {
      commit('getDistricts', data);
    },
    selectCity({ commit }, data) {
      commit('selectCity', data);
    },
    listUserCars({ commit }) {
      commit('getUserCars');
    },
    listUserCartItems({ commit }) {
      commit('getUserCart');
    },
    listBrands({ commit }) {
      commit('getBrands');
    },
    listSizeBrands({ commit }) {
      commit('getSizeBrands');
    },
    listSizeSeasons({ commit }) {
      commit('getSizeSeasons');
    },
    listSizeWidths({ commit }) {
      commit('getSizeWidths');
    },
    listSizeHeights({ commit }) {
      commit('getSizeHeights');
    },
    listSizeRadiuses({ commit }) {
      commit('getSizeRadiuses');
    },
    listYears({ commit }) {
      commit('getYears');
    },
    listModels({ commit }) {
      commit('getModels');
    },
    listVersions({ commit }) {
      commit('getVersions');
    }
  },
  getters: {
    getCart(state) {
      return state.userCart
    },
    getCityList(state) {
      return state.city_list
    },
    getDistrictList(state) {
      return state.district_list
    },
    selectedCity(state) {
      return state.selected_city
    },
    getUserCarList(state) {
      return state.user_cars
    },
    getBrandList(state) {
      return state.car_brands
    },
    getSizeBrandList(state) {
      return state.size_brands
    },
    getSizeSeasonList(state) {
      return state.size_seasons
    },
    getSizeWidthList(state) {
      return state.size_widths
    },
    getSizeHeightList(state) {
      return state.size_heights
    },
    getSizeRadiusList(state) {
      return state.size_radiuses
    },
    getYearList(state) {
      return state.car_years
    },
    getModelList(state) {
      return state.car_models
    },
    getVersionList(state) {
      return state.car_versions
    },
    getUserCartItems(state) {
      return state.userCart
    },
  },
});

export default store;
