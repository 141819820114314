<template>
  <div class="contact-section-container">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-3 working-hours">
          <h6>CANLI DESTEK</h6>
          <p>Hafta içi: 09:00 - 17:30</p>
          <p>Cumartesi: 09:00 - 13:30</p>
        </div>
        <div class="col-12 col-sm-6 col-md-3 contact-section-items whatsapp">
          <a href="https://wa.me/905367727200" target="_blank">
            <div class="contact-icon">
              <i class="fa-brands fa-whatsapp" style="font-size: 50px"></i>
            </div>
            <div class="contact-info">
              <h6>WhatsApp</h6>
              <h6>Destek Hattı</h6>
            </div>
          </a>
        </div>
        <div class="col-12 col-sm-6 col-md-3 contact-section-items phone">
          <a href="tel:0(850) 302 05 02" target="_blank">
            <div class="contact-icon">
              <img src="https://www.servislet.com/icons/phone-icon.svg" alt="Phone" width="40">
            </div>
            <div class="contact-info">
              <h6>Telefon</h6>
              <p>0(850) 302 05 02</p>
            </div>
          </a>
        </div>
        <div class="col-12 col-sm-6 col-md-3 contact-section-items email">
          <a  href="mailto:destek@servislet.com" target="_blank">
            <div class="contact-icon">
              <img src="https://www.servislet.com/icons/email-icon.svg" alt="Email" width="50">
            </div>
            <div class="contact-info">
              <h6>E-Posta</h6>
              <p>destek@servislet.com</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactBand"
}
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .contact-section-items {
    justify-content: flex-start !important;
    /* margin: 1rem 0px 1rem 40px; */
    padding: 0 0px 0px 80px;
    margin-top: 1rem;
    width: 100%;
    overflow: hidden;
  }
}
a {
    color: inherit
}
.contact-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.contact-info h6 {
  font-size: 20px;
}
.contact-section-container {
  background-color: #1c1c1c;
  padding: 30px 0px;
}

.working-hours {
  background-color: #272727;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}

.working-hours h6 {
  font-weight: 700;
  color: #eaeaea;
  margin-bottom: 15px;
}

.working-hours p {
  line-height: 30px;
  color: #eaeaea;
  margin-bottom: 0px;
}

.contact-section-items {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-section-items a {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.contact-section-items a h6 {
  font-weight: 700;
  color: #eaeaea;
  margin-bottom: 0px;
}

.contact-section-items a p {
  line-height: 30px;
  color: #eaeaea;
  margin-bottom: 0px;
}

.contact-icon {
  padding-right: 15px;
}

.contact-icon i {
  font-size: 40px;
  color: #eaeaea;
}
</style>
