<template>
  <div class="megasearch">
    <span class="title">MARKALAR</span>
    <div class="brands mt-4">
      <div class="brand" @click="setAllItems">
        <span class="brand-logo" style="font-size: 14px" @click="setAllItems">TÜMÜ</span>
        <span class="brand-name"></span>
      </div>
      <div class="brand" v-for="brand in brands" :key="brand.$index" @click="setItem(brand)" :class="selected.find(select => select.name === brand.name) ? 'active' : ''">
        <span class="brand-logo"><img class="img" :src="brand.path" :alt="brand.name" width="70" height="auto"></span>
        <span class="brand-name">{{ brand.name.toUpperCase() }}</span>
      </div>
    </div>
    <div class="mt-5 space-between">
      <!-- <span class="small text-muted">Çoklu seçim yapabilirsiniz.</span> -->
      <button class="btn bg-servislet" @click="nextPage()">Devam Et</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "SizeMegaSearchBrand",
  computed: {
    ...mapGetters(['getSizeBrandList']),
    steps() {
      return this.$store.state.megaSearchSteps.size
    },
    brands() {
      return this.getSizeBrandList
    },
  },
  data() {
    return {
      selected: [],
    }
  },
  mounted() {
    this.listSizeBrands()
  },
  methods: {
    ...mapActions(['listSizeBrands']),
    setAllItems() {
      var items = []
      for (let index = 0; index < this.brands.length; index++) {
        const element = this.brands[index];
        items.push({
          name: element.name,
          item: element
        })
      }
      this.selected = items
      this.$store.commit('megaSearchSetBrandForSize', this.selected)
    },
    nextPage() {
      if(this.$store.state.megaSearchDataBySize.brands && this.$store.state.megaSearchDataBySize.brands.length>0) {
        this.$store.commit('increaseMegaSearchSizeStep')
      }
    },
    setItem(brand) {
      var control = this.selected.find(select => select.name === brand.name)
      if (control) {
        const index = this.selected.findIndex(obj => obj.name === brand.name);
        const newData = [
          ...this.selected.slice(0, index),
          ...this.selected.slice(index + 1)
        ]
        this.selected = newData
        this.$store.commit('megaSearchSetBrandForSize', this.selected)
        // this.$store.commit('increaseMegaSearchCarStep')
      } else {
        this.selected.push({
          name: brand.name,
          item: brand
        })
        this.$store.commit('megaSearchSetBrandForSize', this.selected)
        // this.$store.commit('increaseMegaSearchCarStep')
      }
    },
  }
}
</script>

<style scoped>
.title {
  font-size: 12px;
  font-weight: 700;
  color: var(--dark-white);
}
.brands {
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex-flow: row wrap;
  justify-content: left;
  width: 100%;
}

.brand {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.brand:hover .brand-logo {
  border: 5px solid var(--servislet);
}

.brand:hover .brand-logo, .brand:hover .brand-name {
  color: var(--servislet);
}

.brand-logo {
  background-color: var(--gray);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 900;
  border: 5px solid #252525;
  width: 6rem;
  height: 6rem;
}

.brand-name {
  font-weight: 700;
  margin-top: 5px;
  font-size: 13px;
}

.img {
  filter: brightness(0) invert(1);
}

@media only screen and (max-width: 1024px) {
  .brand-logo {
    width: 4rem;
    height: 4rem;
    padding: 35px;
  }

  .brand-name {
    font-size: 13px;
  }

  .img {
    width: 50px;
  }
}

.brand.active {
  color: var(--servislet);
}

.brand.active .brand-logo {
  border-color: var(--servislet);
}
</style>