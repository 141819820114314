/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/pages/index.vue'
import Create_password from "@/pages/password/create";


Vue.use(VueRouter)

const routes = [
  {
    path: '/giris-yap',
    name: 'LoginPage',
    component: () => import('@/pages/login')
  },
  {
    path: '/hemen-katil',
    name: 'RegisterPage',
    component: () => import('@/pages/register')
  },
  {
    path: '/',
    name: 'HomePage',
    component: Home
  },
  {
    path: '/lastik/urunler',
    name: 'ProductsPage',
    component: () => import('@/pages/products')
  },
  {
    path: '/lastik/:brand/urun/:slug/:shop',
    name: 'ProductPage',
    component: () => import('@/pages/product')
  },
  {
    path: '/lastik/profilim',
    name: 'ProfileDashboard',
    component: () => import('@/pages/profile/dashboard')
  },

  {
    path: '/lastik/kampanyalar',
    name: 'CampaignsPage',
    component: () => import('@/pages/campaigns')
  },
  {
    path: '/lastik/kampanya/:slug',
    name: 'CampaignPage',
    component: () => import('@/pages/campaign')
  },
  {
    path: '/lastik/sss/',
    name: 'FAQPage',
    component: () => import('@/pages/faq/index')
  },
  {
    path: '/lastik/sss/:slug',
    name: 'FAQDetailPage',
    component: () => import('@/pages/faq/detail')
  },
  {
    path: '/lastik/araclarim',
    name: 'CorporateCars',
    component: () => import('@/pages/profile/cars/index')
  },
  {
    path: '/lastik/arac-ekle',
    name: 'CorporateCarAdd',
    component: () => import('@/pages/profile/cars/CarAdd')
  },
  {
    path: '/araclarim/:id',
    name: 'CorporateCarUpdate',
    component: () => import('@/pages/profile/cars/CarUpdate')
  },
  {
    path: '/lastik/siparislerim',
    name: 'OrderList',
    component: () => import('@/pages/profile/orders/List.vue')
  },
  {
    path: '/lastik/sepetim',
    name: 'CartPage',
    component: () => import('@/pages/cart')
  },
  {
    path: '/lastik/randevu',
    name: 'AppointmentPage',
    component: () => import('@/pages/appointment')
  },
  {
    path: '/lastik/odeme',
    name: 'PaymentPage',
    component: () => import('@/pages/payment')
  },
  {
    path: '/lastik/odeme-basarili/eft/:order',
    name: 'PaymentSuccessEFT',
    component: () => import('@/pages/success/eft')
  },
  {
    path: '/lastik/odeme-basarili/:order',
    name: 'PaymentSuccess',
    component: () => import('@/pages/success/index')
  },
  {
    path: '/lastik/lastik-rehberi',
    name: 'TireGuide',
    component: () => import('@/pages/guide/index')
  },
  {
    path: '/sifre-olusturma/:token',
    name: 'CreatePassword',
    component: Create_password
  }
]


const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
