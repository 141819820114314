<template>
  <b-sidebar id="mobile-menu" title="Servislet Kurumsal" text-variant="light" backdrop shadow right>
    <div class="mt-5">
      <ul>
        <li><router-link to="/lastik/urunler">Ürünler</router-link></li>
        <li><router-link to="/#">İndirim Kuponları</router-link></li>
        <li><router-link to="/lastik/kampanyalar">Kampanyalar</router-link></li>
      </ul>
    </div>
  </b-sidebar>
</template>

<script>
export default {
  name: "MobileMenu"
}
</script>

<style scoped>
.tag {
  position: absolute;
  background-color: var(--servislet);
  border-radius: 20px;
  color: var(--light);
  padding: 3px 9px;
  font-size: 11px;
  margin-left: 137px;
}
@media screen and (max-width: 600px) {
  ul li a {
    color: var(--light);
    opacity: 0.8 !important;
    font-weight: 600 !important;
  }

  ul li .active {
    opacity: 1 !important;
    font-weight: 800 !important;
  }
}
ul {
  padding: 0 0px;
}

ul li {
  list-style-type: none;
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid;
  border-color: var(--gray);
  display: flex;
  align-items: center;
}

ul li:last-child {
  border: none;
}

ul li a {
  columns: var(--light);
  opacity: 0.5;
  font-weight: 300;
  font-size: 16px;
  text-transform: uppercase;
  margin-right: 5px;
  text-decoration: none;
}

ul li .active {
  opacity: 1;
  font-weight: 400;
}


@media only screen and (device-width: 767px), only screen and (max-width: 767px) {

  #tire-mobile-menu {
    padding: 50px !important;
    z-index: 1030;
  }

  #tire-mobile-menu .b-sidebar-header {
    display: flex !important;
    flex-direction: row-reverse !important;
  }

}
</style>