<template>
  <div class="megasearch">
    <span class="title">RUN FLAT</span>
    <div class="radiuses mt-4">
      <div class="inputs">
        <div>
          <div class="list">
            <div class="select-list">
              <div class="select" @click="setRunflat(2)">
                <i v-if="runflat === 2" class="fas fa-check-circle font-servislet" style="font-size: 30px"></i>
                <span v-else class="box"></span>
                <span class="ml-2">Tümü</span>
              </div>
              <div class="select" @click="setRunflat(1)">
                <i v-if="runflat === 1" class="fas fa-check-circle font-servislet" style="font-size: 30px"></i>
                <span v-else class="box"></span>
                <span class="ml-2">Evet</span>
              </div>
              <div class="select" @click="setRunflat(0)">
                <i v-if="runflat === 0" class="fas fa-check-circle font-servislet" style="font-size: 30px"></i>
                <span v-else class="box"></span>
                <span class="ml-2">Hayır</span>
              </div>
            </div>
            <div class="list-content">
              <div class="content neu-black">
                <div class="content-title">
                  <img src="https://v3-main-demo.servislet.com/icons/radius.png" alt="Yükseklik" width="30" class="mr-2">
                  <span>RUN FLAT NEDİR?</span>
                </div>
                <span class="description">
                  Run flat lastikler, lastiğinizi değiştirmek için bir otomobil mağazasına gitmek veya güvenli, düz bir alan bulmak için zaman ayırabilmeniz için patladıktan sonra sürmeye devam edebileceğiniz lastiklerdir. Yine de, onları süresiz olarak kullanamazsınız. Run flat lastiklerinizle ne kadar hızlı ve ne kadar uzağa gidebileceğinizi öğrenmek için üreticinin teknik özelliklerini kontrol edin.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SizeMegaSearchRunflatComponent",
  data() {
    return {
      runflat: 3
    }
  },
  computed: {
    filter() {
      return this.$store.state.app.tire.megasearch.size
    },
    allBrands() {
      return this.$store.state.app.tire.brand.list
    },
  },
  methods: {
    setRunflat(value) {
      this.$store.commit('megaSearchSetRunflatForSize', value)
      this.$store.commit('increaseMegaSearchSizeStep')
    }
  },
}
</script>

<style scoped>

.title {
  font-size: 12px;
  font-weight: 700;
  color: var(--dark-white);
}

.megasearch-input {
  display: flex;
  flex-direction: column;
  padding: 15px 30px;
}

.megasearch-input input{
  border: none;
  height: 50px;
  font-size: 30px;
  color: var(--servislet);
  font-weight: 900;
  letter-spacing: 50px;
  padding-left: 25px;
  background-color: var(--dark);
}

.cursor {
  display: flex;
  gap: 5px;
}

.cursor span {
  height: 5px;
  background-color: var(--gray);
  width: 65px;
}

cursor .active {
  background-color: var(--servislet);
}

.list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.list-items {
  margin-top: 30px;
  width: 50%;
}

.item-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 30px;
  padding: 30px;
  font-weight: 900;
  color: var(--light-gray);
  max-height: 50vh;
  overflow: auto;
}

.item-list::-webkit-scrollbar {
  display: none;
}

.list-content {
  margin-top: 30px;
}

.content {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-weight: 700;
  line-height: 1.4;
}

.content-title {
  display: flex;
  align-items: center;
}

.img-content {
  position: fixed;
  bottom: 0;
  right: 8%;
}

.seperator {
  font-size: 30px;
  font-weight: 700;
  color: var(--servislet);
}

.content .description{
  font-weight:200;
  font-size:14px;
}

.select-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: none !important;
}

.select {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.box {
  width: 30px;
  height: 30px;
  background-color: var(--gray);
  border-radius: 2px
}

@media only screen and (max-width: 1024px) {
  .list-content {
    display: none;
  }
}
</style>
