<template>
  <div class="megasearch">
    <span class="title">ARAÇ VERSİYONU</span>
    <div class="car-model mt-4">
      <div class="inputs neu-black">
        <span class="before-input">{{ $store.state.megaSearchData.brand.name }}  {{ $store.state.megaSearchData.year }}  {{ $store.state.megaSearchData.model.name }}  </span>
        <input type="text" class="model-input" ref="focus" v-model="search">
      </div>
      <div class="list">
        <span class="cursor-pointer" v-for="version in filteredVersion" :key="version.$index">
          <span class="version-content" @click="setVersion(version)">
            <span class="active">{{ $store.state.megaSearchData.brand.name }}  {{ $store.state.megaSearchData.year }}  {{ $store.state.megaSearchData.model.name }}</span>
            <span class="info-seperator mr-1 ml-2">  </span>
            <div class="info">
              <span class="version-name">{{ version.version_name_1 }}</span>
              <span>{{ version.version_name_2 }}</span>
            </div>
          </span>
        </span>
      </div>
      <!-- <div class="list-placeholder" v-else>
        <b-skeleton width="70%" v-for="placeholder in 20" :key="placeholder.$index" />
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "CarMegaSearchVersion",
  data() {
    return {
      search: '',
    }
  },
  mounted() {
    //this.$refs.focus.focus()
    this.listVersions()
  },
  computed: {
    ...mapGetters(['getVersionList', 'selectedCity']),
    filteredVersion() {
      if (this.search.trim().length === 0) {
        return this.getVersionList
      } else {
        return this.getVersionList.filter(version => version.version_name_1.toLocaleUpperCase('tr-TR').indexOf(this.search.toLocaleUpperCase('tr-TR')) > -1)
      }
    },
    steps() {
      return this.$store.state.megaSearchSteps.car
    },
  },
  methods: {
    ...mapActions(['listVersions']),
    setVersion(version) {
      this.$store.commit('megaSearchSetVersion', version)
      this.$store.commit('increaseMegaSearchCarStep')
      // this.$store.commit('shared/car/setSelected', version)
      // this.$store.commit('shared/car/setCarSearch', true)
      //   if(version.tire) {
      //     this.$router.push(this.localePath({
      //       name: 'tire-products', query: {
      //         height: version.tire.height,
      //         width: version.tire.width,
      //         radius: version.tire.radius,
      //       }}
      //     ))
      //   }
      //   else{
      //     this.$swal({
      //       icon: 'error',
      //       toast: true,
      //       position: 'top-end',
      //       showConfirmButton: false,
      //       timer: 5000,
      //       text: 'Seçtiğiniz araç versiyonuna ait lastik bilgisi   bulunamadı. Lastik ebatlarınızı biliyorsanız ebata göre arama   yapabilirsiniz.'
      //     })
      //   }
    },
  }
}
</script>

<style scoped>
.title {
  font-size: 12px;
  font-weight: 700;
  color: var(--dark-white);
}

.inputs {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 15px 0px;
  padding-left: 25px;
}

.inputs input {
  border: none;
  height: 50px;
  font-size: 30px;
  color: var(--servislet);
  font-weight: 900;
  background-color: var(--dark);
}

.list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
  width: auto;
  padding-left: 25px;
  font-size: 30px;
  max-height: 43vh;
  overflow-y: scroll;
  font-weight: 900;
  color: var(--light-gray);
}

.list::-webkit-scrollbar {
  display: none;
}

.active {
  /* color: var(--servislet);
  opacity: 0.4; */
  color: #f4f4f4;
  opacity: 1;
}
.input-seperator {
  font-weight: 900;
  font-size: 30px;
  color: var(--servislet);
}

.list-placeholder {
  margin-top: 50px;
}

.before-input {
  font-weight: 900;
  font-size: 30px;
  /* color: var(--servislet); */
  color: var(--light);
}

.version-item {
  display: flex;
  align-items: center;
}

.info {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 13px;
}

.version-name {
  color: var(--dark-white);
}

.version-content {
  display: flex;
}

@media only screen and (max-width: 1024px) {
  .inputs {
    display: none;
  }

  .list {
    font-size: 14px;
    margin-top: 20px;
    padding-left: 0px;
  }

  .version-content {
    flex-direction: column;
    gap: 10px;
  }

  .info {
    margin-left: 0;
    border-bottom: 1px solid var(--gray);
    padding-bottom: 10px;
  }

  .info-seperator {
    display: none;
  }

  .list-placeholder {
    margin-top: 20px;
  }
}
</style>