<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  beforeMount() {
    document.body.style.backgroundColor = "#EAEAEA";
  },
  mounted() {
    if(!this.$route.fullPath.includes('/sifre-olusturma/') && this.$route.fullPath !== '/giris-yap'){
      if (!localStorage.userData) {
        this.$router.push('/giris-yap')
      }
    }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "@/css/style.css";
@import "@/css/responsive.css";

#app {
  font-family: 'Mulish', sans-serif;
}
</style>
