<template>
  <div>
    <div class="container enews-container mt-2">
      <div class="row">
        <div class="col-12 flex-center-space">
          <div class="dont-miss-text">
            <h6 class="bolder" style="font-size: 35px">Kampanyaları Kaçırma</h6>
            <p class="mt-3">Servislet kampanyaları ile ihtiyacınız olan ürün ve hizmetleri en uygun fiyata  ve cazip ödeme seçenekleri ile satın alın.</p>
          </div>
          <div class="enews-input">
            <form
                action="https://servislet.us14.list-manage.com/subscribe/post?u=1d496bdddaf732e83ce90005d&amp;id=490784f5b2&amp;f_id=005f82e0f0"
                method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate"
                target="_blank" novalidate>
              <input type="email" value="" name="EMAIL" id="mce-EMAIL" required placeholder="E-Posta" class="email">
              <button>Üye Ol</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsletterBand"
}
</script>

<style scoped>


.enews-container {
  padding-top: 50px;
  padding-bottom: 50px;
}

.dont-miss-text {
  max-width: 550px;
}

.dont-miss-text h6 {
  font-size: 24px;
}

.enews-input {
  display: flex;
  align-items: center;
  max-width: 500px;
  width: 100%;
  justify-content: flex-end;
}

.enews-input form {
  position: relative;
  width: 100%;
  padding-left: 20px;
}

.enews-input form button {
  position: absolute;
  right: 11px;
  top: 10px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #1c1c1c;
  color: #eaeaea;
  padding: 20px;
  width: 120px;
  border-radius: 5px;
  font-size: 14px;
}

.enews-input form input {
  width: 100%;
  max-width: 500px;
  height: 65px !important;
  padding-left: 15px;
  background-color: #eaeaea;
  border: 1.5px solid #1c1c1c;
  border-radius: 10px;
}

.enews-input button:hover {
  background-color: #06a5d0;
}

@media only screen and (device-width: 767px),
only screen and (max-width: 767px) {

  .contact-section-container {
    padding: 15px 15px;
  }

  .contact-section-items a {
    width: 210px;
    margin-right: 0px;
  }

  .contact-section-items {
    margin: 20px 0px;
  }

  .working-hours {
    margin-bottom: 20px;
  }

  .space-between {
    flex-direction: column;
  }

  .enews-input form {
    padding-left: 0px;
  }

  .enews-input {
    margin-top: 20px;
  }
  .col-12 {
    flex-direction: column;
  }
}
</style>