<template>
  <div id="login-wrapper" class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-6" id="form">
        <div class="form-wrapper">
          <img alt="Servislet Kurumsal" src="@/assets/servislet-kurumsal.png">
          <ValidationObserver ref="createPasswordRules">
            <div class="form-width">
              <div class="input-wrapper" id="pass">
                <ValidationProvider #default="{errors}" name="Şifre" rules="required|min:6" vid="password">
                  <b-input-group class="mt-3">
                    <template #prepend>
                      <b-input-group-text>
                        <img src="@/assets/icons/lock.png">
                      </b-input-group-text>
                    </template>
                    <b-form-input placeholder="Şifre" type="password" v-model="form.password" v-on:keyup.enter="create()" v-if="password"></b-form-input>
                    <b-form-input placeholder="Şifre" type="text" v-model="form.password" v-on:keyup.enter="create()" v-else></b-form-input>
                    <template #append>
                      <b-input-group-text id="show-pass">
                        <img src="@/assets/icons/eye.png" class="show-pass" @click="password = !password">
                      </b-input-group-text>
                    </template>
                  </b-input-group>
                  <div class="error-label">
                    <label for="email" v-if="errors.length>0" class="text-danger"><small>*</small> {{errors[0]}}.</label>
                  </div>
                </ValidationProvider>
              </div>
              <div class="input-wrapper mt-4" id="pass">
                <ValidationProvider #default="{errors}" name="Şifre" rules="required|min:6|confirmed:password">
                  <b-input-group class="mt-3">
                    <template #prepend>
                      <b-input-group-text>
                        <img src="@/assets/icons/lock.png">
                      </b-input-group-text>
                    </template>
                    <b-form-input placeholder="Şifre Tekrar" type="password" v-model="form.password_confirmation" v-on:keyup.enter="create()" v-if="password_confirmation"></b-form-input>
                    <b-form-input placeholder="Şifre Tekrar" type="text" v-model="form.password_confirmation" v-on:keyup.enter="create()" v-else></b-form-input>
                    <template #append>
                      <b-input-group-text id="show-pass">
                        <img src="@/assets/icons/eye.png" class="show-pass" @click="password_confirmation = !password_confirmation">
                      </b-input-group-text>
                    </template>
                  </b-input-group>
                  <div class="error-label">
                    <label for="email" v-if="errors.length>0" class="text-danger"><small>*</small> {{errors[0]}}.</label>
                  </div>
                </ValidationProvider>
              </div>
              <div class="login">
                <button @click="create()">Oluştur</button>
              </div>
            </div>
          </ValidationObserver>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6" id="carousel">
        <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="4000"
            controls
            indicators
            background="#ababab"
            img-width="100%"
            img-height="100%"
            style="text-shadow: 1px 1px 2px #333;"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
        >
          <!-- Text slides with image -->
          <b-carousel-slide
              caption="Avantajlı ödeme şekillerinden yararlanın!"
              text="Servislet’in çalıştığınız bankalarla kurduğu iş birlikleri sayesinde taksit planlarından yararlanabilir, alışveriş kredisi ile ihtiyaçlarınızı satın alabilir ve her zaman en avantajlı ödeme şeklinden yararlanabilirsiniz."
              img-src="/img/login/1.webp"
          ></b-carousel-slide>
          <b-carousel-slide
              caption="Ürün çeşitliliği"
              text="Servislet Kurumsal’ın marka ve model çeşitliliği oldukça zengindir. Geniş ürün portföyü ile her araç tipine, her ebatta ve her ihtiyaca uygun lastik çeşitlerine kolayca ulaşırsınız."
              img-src="/img/login/2.webp"
          ></b-carousel-slide>
          <b-carousel-slide
              caption="Toplu arama özelliğinden yararlanın, zamandan tasarruf edin!"
              text="Tüm araçlarınıza uygun lastikleri tek sayfada görüntüleyebilir, ihtiyacınıza uygun lastiği kolayca seçebilir ve hemen satın alabilirsiniz."
              img-src="/img/login/3.webp"
          ></b-carousel-slide>
          <b-carousel-slide
              caption="Daha çok al, daha az öde!"
              text="Toplu satın alımlarda avantajlı fiyatlardan yararlanabilirsiniz. Araçlarınız için ihtiyacınız olan lastik ve ek servis adedi arttıkça daha az ödersiniz."
              img-src="/img/login/4.webp"
          ></b-carousel-slide>
        </b-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreatePassword",
  data() {
    return {
      slide: 0,
      sliding: null,
      password: true,
      password_confirmation: true,
      form: {
        password: '',
        password_confirmation:''
      }
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    },
    create() {
      this.$refs.createPasswordRules.validate().then(success=>{
        if(success){
          this.$axios.post(`/web/corporate/auth/reset-password`, {
            token: this.$route.params.token,
            password: this.form.password,
            password_confirmation: this.form.password_confirmation,
            type: 'corporate-welcome'
          }).then((response) => {
            if(response.status) {
              this.$swal.fire({
                position: 'top-end',
                icon: 'success',
                toast: true,
                text: 'Şifreniz başarıyla oluşturuldu, giriş sayfasına yönlendiriliyorsunuz.',
                showConfirmButton: false,
                timer: 1500
              })
              setTimeout(()=>{
                this.$router.push('/giris-yap')
              },1500)

            }
          }).catch((error) => {
            console.log(error.response)
            this.$swal.fire({
              position: 'top-end',
              icon: 'error',
              toast: true,
              text: error.response.data.responseData.error[0].message,
              showConfirmButton: false,
              timer: 1500
            })
          })
        }else{
          this.$swal.fire({
            position: 'top-end',
            icon: 'error',
            toast: true,
            text: 'Lütfen alanları kontrol edin.',
            showConfirmButton: false,
            timer: 1500
          })
        }
      })
    }
  }



}
</script>

<style scoped>
.row{
  display: flex;
  justify-content: center;
}
#login-wrapper {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
#login-wrapper img {
  max-width: 290px;
}
#login-wrapper .logo {
  margin-bottom: 2rem;
}
#form {
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-wrapper {
  width: 100%;
  position: relative;
}
.input-wrapper input {
  color: #1c1c1c;
  border: 1px solid #DEDEDE;
  background-color: #EAEAEA;
  padding: 6px 12px;
  border-radius: 5px;
}
.forgot-pass p {
  text-align: right;
  cursor: pointer;
  transition: .5s;
  margin-top: 1rem;
}
.forgot-pass p:hover {
  transition: .5s;
  text-decoration: underline;
}
.login{
  margin-top: 1.5rem;
}
.login button {
  width: 100%;
  background: #1c1c1c;
  border: 1px solid #1c1c1c;
  color: #f4f4f4;
  border-radius: 5px;
  outline: none;
  transition: .5s;
  height: 40px;
}
.login button:hover {
  transition: .5s;
  background: #06a5d0;
  border-color: #06a5d0;
  transition: .5s;
}
.form-width {
  width: 420px;
  margin-top: 2rem;
}
#carousel{
  height: 100vh;
}
@media only screen and (max-width: 600px) {
  #carousel {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 1200px) {
  #carousel {
    display: none;
    padding: 0 !important;
  }
  #login-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.error-label{
  text-align: left;
  font-size: 14px;
}
</style>
