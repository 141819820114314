<template>
  <div class="megasearch">
    <span class="title">YÜKSEKLİK</span>
    <div class="heights mt-4">
      <div class="inputs">
        <div>
          <div class="megasearch-input neu-black" @click="focusInput">
            <div class="inputs-text">
              <span>{{ width }}/</span>
              <input type="text" maxlength="3" v-model="search" ref='focus' v-mask="'##'" class="disabled-input-2" @keyup="controlHeight">
            </div>
          </div>
          <div class="list">
            <div class="list-items">
              <div class="item-list">
                <span v-for="height in filteredHeight" :key="height" class="cursor-pointer" @click="setHeight(height)">{{ width }}/{{ height }}</span>
              </div>
            </div>
            <div class="list-content">
              <div class="content neu-black">
                <div class="content-title">
                  <img src="https://v3-main-demo.servislet.com/icons/height.png" alt="Yükseklik" width="30" class="mr-2">
                  <span>YÜKSEKLİK</span>
                </div>
                <span class="description">Genellikle lastiğin kesit genişliğini takip eden iki basamaklı sayı, bize en-boy oranını veya lastik profili ölçümünü söyler. Bu örnekte 55, tekerlek jantından dişin dışına kadar olan yan duvar mesafesinin kesit genişliğinin %45'i olduğunu gösterir. Daha düşük bir en/boy oranı, daha kısa yanağı olan daha düşük profilli bir lastik anlamına gelirken, daha yüksek en/boy oranına sahip bir lastiğin yanakları daha uzun olacak ve daha çok bir halka gibi görünecektir.</span>
              </div>
            </div>
          </div>
          <!-- <div class="list-placeholder" v-else>
            <b-skeleton width="30%" v-for="placeholder in 20" :key="placeholder.$index" />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "SizeMegaSearchHeight",
  data() {
    return {
      search: ''
    }
  },
  computed: {
    ...mapGetters(['getSizeHeightList']),
    heights() {
      return this.getSizeHeightList
    },
    width() {
      return this.$store.state.megaSearchDataBySize.width
    },
    steps() {
      return this.$store.state.megaSearchSteps.size
    },
    filteredHeight() {
      if (this.search.trim().length === 0) {
        return this.getSizeHeightList
      } else {
        return this.getSizeHeightList.filter(item => item.toString().toLocaleUpperCase('tr-TR').indexOf(this.search.toLocaleUpperCase('tr-TR')) > -1)
      }
    },
  },
  methods: {
    ...mapActions(['listSizeHeights']),
    controlHeight() {
      if (this.search.length === 2) {
        if (this.getSizeHeightList.includes(parseInt(this.search))) {
          this.setHeight(parseInt(this.search))
        }
      }
      if (this.search.length === 0) {
        this.$store.commit('decreaseMegaSearchSizeStep')
      }
    },
    focusInput() {
      this.$refs.focus.focus()
    },
    setHeight(height) {
      this.search = height.toString()
      this.$store.commit('megaSearchSetHeightForSize', this.search)
      this.$store.commit('increaseMegaSearchSizeStep')
    }
  },
  mounted() {
    this.$refs.focus.focus()
    this.listSizeHeights()
  }
}
</script>

<style scoped>

.title {
  font-size: 12px;
  font-weight: 700;
  color: var(--dark-white);
}

.megasearch-input {
  display: flex;
  font-weight: 900;
  flex-direction: column;
  padding: 15px 18px;
}

.inputs-text {
  font-size: 30px;
  color: var(--light);
}

.inputs input {
  border: none;
  height: 50px;
  font-size: 30px;
  color: var(--servislet);
  font-weight: 900;
  margin-left: .25rem;
  background-color: var(--dark);
}

.model-input {
  padding-left: 0px !important;
}

.cursor {
  display: flex;
  gap: 5px;
}

.cursor span {
  height: 5px;
  background-color: var(--gray);
  width: 65px;
}

cursor .active {
  background-color: var(--servislet);
}

.list {
  display: flex;
  gap: 20px;
}

.list-items {
  width: 100%;
}

.item-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 30px;
  padding: 30px 18px;
  font-weight: 900;
  color: var(--light-gray);
  max-height: 50vh;
  overflow: auto;
}

.item-list::-webkit-scrollbar {
  display: none;
}

.list-content {
  margin-top: 30px;
  width: 100%;
}

.content {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-weight: 700;
  line-height: 1.4;
}

.content-title {
  display: flex;
  align-items: center;
}

.content .description{
  font-weight:200;
  font-size:14px;
}

.img-content {
  position: fixed;
  bottom: 0;
  right: 8%;
}

.seperator {
  font-size: 30px;
  font-weight: 900;
  color: var(--servislet);
}

@media only screen and (max-width: 1024px) {
  .list-content {
    display: none;
  }

  .megasearch-input {
    padding: 10px;
  }

  .megasearch-input input{
    border: none;
    height: 50px;
    font-size: 25px;
    background-color: var(--dark);
    color: var(--servislet);
    font-weight: 900;
    letter-spacing: 21px;
    padding-left: 5px;
  }

  .cursor span {
    width: 30px;
  }

  .disabled-input-1 {
    width: 105px !important;
  }

  .disabled-input-2 {
    width: 105px !important;
  }


  .seperator {
    font-size: 25px;
  }

  .cursor-seperator {
    margin-left: 18px;
  }
}

.list-placeholder {
  margin-top: 30px;
}

@media only screen and (max-width: 580px) {
  .item-list {
    padding-left: 10px;
  }

  .list-placeholder {
    padding-left: 10px;
  }
}
</style>
