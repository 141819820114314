<template>
  <div class="megasearch">
    <span class="title">JANT ÇAPI</span>
    <div class="radiuses mt-4">
      <div class="inputs">
        <div>
          <div class="megasearch-input neu-black" @click="focusInput">
            <div class="inputs-span">
              <span>{{width}}/{{height}} R</span>
              <input type="text" maxlength="2" v-model="search" ref='focus' v-mask="'##'" class="input-radius" @keyup="controlRadius"  v-on:keyup.delete="deleteRadius"> <!--  @keyup="controlRadius"  v-on:keyup.delete="deleteRadius"  -->
            </div>
          </div>
          <div class="list">
            <div class="list-items">
              <div class="item-list">
                <span v-for="radius in filteredRadius" :key="radius" class="cursor-pointer" @click="setRadius(radius)">{{ width }}/{{ height }} R{{ radius }}</span>
              </div>
            </div>
            <div class="list-content">
              <div class="content neu-black">
                <div class="content-title">
                  <img src="https://v3-main-demo.servislet.com/icons/radius.png" alt="Yükseklik" width="30" class="mr-2">
                  <span>YARI ÇAP</span>
                </div>
                <span class="description">
                  Yine aşağıdan örnek lastik ebadımızı kullanarak, 16, lastiğin 16 inç çaplı bir jantla eşleşmesi gerektiği anlamına gelir. Lastikler genellikle aşağıdaki genişliklerde (inç olarak) gelir: 8, 10, 12, 13, 14, 15, 17, 18, 19, 20, 22, 23, 24, 26 ve 28. Bu ebatlardaki lastikler genellikle çoğu binek otomobilde, hafif ticari kamyonlarda, SUV'lerde, minivanlarda ve minibüslerde bulunur.
                </span>
              </div>
            </div>
          </div>
          <!-- <div class="list-placeholder" v-else>
            <b-skeleton width="43%" v-for="placeholder in 20" :key="placeholder.$index" />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "SizeMegaSearchRadiusComponent",
  data() {
    return {
      search: '',
      delete_keydown_counter:0
    }
  },
  computed: {
    ...mapGetters(['getSizeRadiusList']),
    radiuses() {
      return this.getSizeRadiusList
    },
    width() {
      return this.$store.state.megaSearchDataBySize.width
    },
    height() {
      return this.$store.state.megaSearchDataBySize.height
    },
    filteredRadius() {
      if (this.search.trim().length === 0) {
        return this.getSizeRadiusList
      } else {
        return this.getSizeRadiusList.filter(item => item.toString().toLocaleUpperCase('tr-TR').indexOf(this.search.toLocaleUpperCase('tr-TR')) > -1)
      }
    },
  },
  methods: {
    ...mapActions(['listSizeRadiuses']),
     controlRadius() {
      if (this.search.length === 2) {
        if (this.radiuses.includes(parseInt(this.search))) {
          this.setRadius(parseInt(this.search))
        }
      }
    },
    deleteRadius(){
      this.delete_keydown_counter++;
      if (this.search.length === 0 && this.delete_keydown_counter >2) {
        this.$store.commit('app/tire/megasearch/setSizeClearItems', 4)
      } else if(this.search.length > 0 && this.delete_keydown_counter >1){
        this.$store.commit('app/tire/megasearch/setSizeClearItems', 5)
      }
    },
    focusInput() {
      this.$refs.focus.focus()
    },
    setRadius(radius) {
      this.search = radius.toString()
      this.$store.commit('megaSearchSetRadiusForSize', this.search)
      this.$store.commit('increaseMegaSearchSizeStep')
    }
  },
  mounted() {
    this.$refs.focus.focus()
    this.listSizeRadiuses()
  }
}
</script>

<style scoped>

.title {
  font-size: 12px;
  font-weight: 700;
  color: var(--dark-white);
}

.megasearch-input {
  display: flex;
  flex-direction: column;
  padding: 15px 18px;
}

.inputs-span {
  font-size: 30px;
  color: var(--light) !important;
  font-weight: 900;
}

.megasearch-input input{
  border: none;
  height: 50px;
  font-size: 30px;
  color: var(--servislet);
  font-weight: 900;
  background-color: var(--dark);
  margin-left: .25rem;
}

.cursor {
  display: flex;
  gap: 5px;
}

.cursor span {
  height: 5px;
  background-color: var(--gray);
  width: 65px;
}

cursor .active {
  background-color: var(--servislet);
}

.list {
  display: flex;
  gap: 20px;
}

.list-items {
  width: 100%;
}

.item-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 30px;
  padding: 30px 20px;
  font-weight: 900;
  color: var(--light-gray);
  max-height: 50vh;
  overflow: auto;
}

.item-list::-webkit-scrollbar {
  display: none;
}

.list-content {
  margin-top: 30px;
  width: 100%;
}

.content {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-weight: 700;
  line-height: 1.4;
}

.content-title {
  display: flex;
  align-items: center;
}

.img-content {
  position: fixed;
  bottom: 0;
  right: 8%;
}

.seperator {
  font-size: 30px;
  font-weight: 900;
  color: var(--servislet);
}

.input-radius {
  margin-left: -7px;
}

.content .description{
  font-weight:200;
  font-size:14px;
}

@media only screen and (max-width: 1024px) {
  .list-content {
    display: none;
  }

  .megasearch-input {
    padding: 10px;
  }

  .megasearch-input input{
    border: none;
    height: 50px;
    font-size: 25px;
    background-color: var(--dark);
    color: var(--servislet);
    font-weight: 900;
    letter-spacing: 21px;
    padding-left: 5px;
    width: 80px;
  }

  .cursor span {
    width: 30px;
  }

  .disabled-input-1 {
    width: 105px !important;
  }

  .disabled-input-2 {
    width: 67px !important;
  }


  .seperator {
    font-size: 25px;
  }

  .cursor-seperator {
    margin-left: 16px !important;
  }

  .cursor-seperator-2 {
    margin-left: 27px !important;
  }

  .input-radius {
    margin-left: 5px;
  }
}

.list-placeholder {
  margin-top: 30px;
}

@media only screen and (max-width: 580px) {
  .item-list {
    padding-left: 10px;
  }

  .list-placeholder {
    padding-left: 10px;
  }
}
</style>
