<template>
  <b-sidebar id="profile-sidebar"  text-variant="light" style="background-color: #1c1c1c !important" backdrop shadow right lazy no-header>
    <div class="sidebar-close-button" @click="closeBar">
      <img src="/img/icons/closebutton.svg" alt="Close" width="20">
    </div>
    <div class="sidebar-links">
      <ul>
        <li>
            <router-link to="/lastik/profilim">Profil</router-link>
        </li>
        <li>
            <router-link to="/lastik/siparislerim">Siparişler</router-link>
        </li>
        <li>
            <router-link to="/">Favoriler</router-link>
        </li>
        <li class="garage">
          
          <router-link to="/lastik/araclarim">Araçlar</router-link> <span class="small font-light-gray ml-2" v-if="cars.length > 0">  
          <span v-if="cars.lenght > 2">En fazla 2 adet araç gösteriliyor.</span>
        </span>
          <ul v-if="cars.length > 0">  
            <li>
              <div class="sidebar-cars">
                <div class="sidebar-car"  v-for="item in cars" :key="item.$imdex"> <!-- v-for="(car, key) in cars" :key="car.$index" v-if="key < 2" @click="setDefault(car)" -->
                  <div class="sidebar-car-content">
                    <span v-if="item.is_default === true"> 
                      <i class="fa fa-check"></i>
                    </span>
                    <span class="checkbox" v-else></span>
                    <div class="sidebar-car-brand">
                      <img :src="item.car.brand.path">
                    </div>
                    <div class="sidebar-car-info">
                      <span>
                        {{ item.name ? item.name : '-' }}
                        <span class="small font-light-gray mr-2" v-if="item.is_default === true">(Varsayılan)</span> 
                      </span>
                      <span class="font-light-gray">{{ item.car.brand.name }} {{ item.car.model.name }}</span>
                      <span class="small font-light-gray">Plaka: {{ item.car_plate ? item.car_plate : '-' }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="" v-else>
                <b-skeleton width="100%" />
                <b-skeleton width="100%" />
                <b-skeleton width="100%" />
                <b-skeleton width="100%" />
                <b-skeleton width="100%" />
                <b-skeleton width="100%" />
                <b-skeleton width="100%" />
                <b-skeleton width="100%" />
                <b-skeleton width="100%" />
              </div> -->
            </li>
          </ul>
          <!-- <span class="small font-light-gray ml-2" v-else> Kayıtlı aracınız bulunmuyor.</span><br> -->
          <button class="black-bg-button" v-b-toggle.car-add-sidebar-full><i class="fa-sharp fa-solid fa-plus"></i> Araç Ekle</button>
        </li>
        <li>
            <router-link to="/lastik/sss">S.S.S</router-link>
        </li>
        <li>
            <router-link to="/">Ayarlar</router-link>
        </li>
        <li>
          <a @click="logout" class="hovered">
            Çıkış Yap
          </a>
        </li>
      </ul>
    </div>
  </b-sidebar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "UserSidebar",
  computed: {
    ...mapGetters(['getUserCarList']),
    cars() {
      return this.getUserCarList
    },
  },
  mounted() {
    this.listUserCars()
  },
  /*
  computed: {
    user() {
      return this.$store.state.shared.auth.login.user
    },
    cars() {
      return this.$store.state.shared.auth.login.cars.list
    }
  },
  watch: {
    user() {
      if (this.cars.length === 0) {
        this.$store.dispatch('shared/auth/getUserCarList', {
          load: true
        }).then(() => {
          var car = this.cars.find((car) => car.is_default === true)
          if (car) {
            this.$store.commit('shared/car/setDefaultCar', car)
          }
        })
      }
    }
  },
  */
  methods: {
    ...mapActions(['listUserCars']),
    setDefault(car) {
      this.$store.dispatch('shared/auth/setDefaultCar', car.id)
    },
    logout() {
      localStorage.removeItem("userData")
      window.location.href = "/giris-yap"
    },
    closeBar() {
      this.$root.$emit('bv::toggle::collapse', 'profile-sidebar')
    }
  }
}
</script>

<style scoped>
a {
  cursor: pointer;
}
.sidebar-links {
  margin-top: 30px;
}

.sidebar-links ul li a {
  font-size: 20px;
  font-weight: 600;
}

.sidebar-links ul {
  padding-left: 0px;
}

.sidebar-links ul li {
  margin-bottom: 20px;
  list-style-type: none;
}

.sidebar-links ul li a strong {
  font-weight: 600;
  margin-bottom: 20px;
}

.garage {
  border-top: 2px dashed;
  border-bottom: 2px dashed;
  border-color: var(--light-gray);
  padding-top: 30px;
  padding-bottom: 30px;
}

.garage ul {
  margin-top: 15px;
}

.sidebar-car {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid var(--dark-gray);
}

.sidebar-car:last-of-type {
  border-bottom: 0;
}

.sidebar-car-content {
  display: flex;
  align-items: center;
  margin: 15px 0px;
}


.sidebar-car .sidebar-car-brand {
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  padding: 5px;
  border-radius: 50px;
  background-color: var(--light);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.sidebar-car .sidebar-car-brand img {
  padding: 10px;
  width: 100%;
}

.sidebar-car-info {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.sidebar-car-info span {
  font-weight: 600;
}

.sidebar-car-info p {
  margin-bottom: 0px;
  margin-top: 10px;
  font-weight: 400;
  color: var(--light-gray);
}

.fa-check-circle {
  color: var(--servislet);
}


.checkbox {
  width: 22px;
  height: 22px;
  min-width: 22px;
  background-color: var(--dark-gray);
  border-radius: 2px;
}

.fa-check {
  font-size: 20px;
  background-color: var(--servislet);
  border-radius: 2px;
}

.black-bg-button {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray);
  color: var(--theme-light);
  border: none;
  border-color: var(--gray);
  border-radius: 5px;
  margin-top: 30px;
  transition: .3s;
}

.black-bg-button:hover {
  background-color: black;
}

.black-bg-button i {
  margin-right: 15px;
}
</style>
