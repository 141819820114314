<template>
  <div class="megasearch">
    <span class="title">ARAÇ MODELİ</span>
    <div class="car-model mt-4">
      <div class="inputs neu-black">
        <span class="before-input">{{ $store.state.megaSearchData.brand.name }}  {{ $store.state.megaSearchData.year }}  </span>
        <input type="text" class="model-input" ref="focus" v-model="search">
      </div>
      <div class="list">
        <span class="cursor-pointer" @click="setModel(model)" v-for="model in filteredModel" :key="model.$index">
          <span>
            <span class="active">{{ $store.state.megaSearchData.brand.name }}  {{ $store.state.megaSearchData.year }}</span>
            <span class="mr-1"></span>
            {{ model.car_model_name }}
          </span>
        </span>
      </div>
      <!-- <div class="list-placeholder" v-else>
        <b-skeleton width="55%" v-for="placeholder in 20" :key="placeholder.$index" />
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "CarMegaSearchModel",
  data() {
    return {
      search: '',
    }
  },
  mounted() {
    // this.$refs.focus.focus()
    this.listModels()
  },
  computed: {
    ...mapGetters(['getModelList']),
    filteredModel() {
      if (this.search.trim().length === 0) {
        return this.getModelList
      } else {
        return this.getModelList.filter(model => model.car_model_name.toLocaleUpperCase('tr-TR').indexOf(this.search.toLocaleUpperCase('tr-TR')) > -1)
      }
    },
  },
  methods: {
    ...mapActions(['listModels']),
    setModel(model) {
        this.$store.commit('megaSearchSetModel', model)
        this.$store.commit('increaseMegaSearchCarStep')
    },
  }
}
</script>

<style scoped>
.title {
  font-size: 12px;
  font-weight: 700;
  color: var(--dark-white);
}

.inputs {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 15px 0px;
  padding-left: 25px;
}

.inputs input {
  border: none;
  height: 50px;
  font-size: 30px;
  color: var(--servislet);
  font-weight: 900;
  background-color: var(--dark);
}

.list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
  width: auto;
  padding-left: 25px;
  font-size: 30px;
  max-height: 45vh;
  overflow-y: scroll;
  font-weight: 900;
  color: var(--light-gray);
}

.list::-webkit-scrollbar {
  display: none;
}

.list-placeholder {
  margin-top: 50px;
}

.active {
  /* color: var(--servislet);
  opacity: 0.4; */
  color: #f4f4f4;
  opacity: 1;
}
.input-seperator {
  font-weight: 900;
  font-size: 30px;
  color: var(--servislet);
}

.before-input {
  font-weight: 900;
  font-size: 30px;
  /* color: var(--servislet); */
  color: var(--light);
}

@media only screen and (max-width: 1024px) {
  .inputs {
    display: none;
  }

  .list {
    font-size: 14px;
    margin-top: 20px;
    padding-left: 0px;
  }

  .list-placeholder {
    margin-top: 20px;
  }
}
</style>