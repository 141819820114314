<template>
  <div id="campaign-carousel-wrapper">
    <ssr-carousel
        :responsive="responsive"
        :slides-per-page="slidesPerPage"
        :gutter="gutter"
        :paginate-by-slide="paginateBySlide"
        :pagination-label="paginationLabel"
        :autoplay-delay="autoplayDelay"
        :loop="loop"
        :center="center"
        :peek="peek"
        :peek-left="peekLeft"
        :peek-right="peekRight"
        :peek-gutter="peekGutter"
        :overflow-visible="overflowVisible"
        :no-drag="noDrag"
        :show-arrows="showArrows"
        :show-dots="showDots"
    >
        <div class="slide" v-for="item in products" :key="item.$index">
            <ProductCard :product="item" :is-list="isList" />
        </div>
    </ssr-carousel>
  </div>
</template>

<script>
/* eslin-disable */
import ProductCard from '@/components/ProductCard.vue'

export default {
  name: 'ProductsCarousel',
  components: { ProductCard },
  data() {
    return {
        responsive: [
        {
          maxWidth: 1280,
          slidesPerPage: 5,
        },
        {
          maxWidth: 1024,
          slidesPerPage: 3,
        },
        {
          maxWidth: 767,
          slidesPerPage: 2
        }
      ]
    }
  },
  props: {
    slidesPerPage: {
        default: 5,
        type: Number
    },
    gutter: {
        default: 20,
        type: Number
    },
    paginateBySlide: {
        default: false,
        type: Boolean
    },
    paginationLabel: {
        default: null,
        type: Text
    },
    autoplayDelay: {
        default: 0,
        type: Number
    },
    loop: {
        default: false,
        type: Boolean
    },
    center: {
        default: false,
        type: Boolean
    },
    peek: {
        default: 0,
        type: Number
    },
    peekLeft: {
        default: 5,
        type: Number
    },
    peekRight: {
        default: 0,
        type: Number
    },
    peekGutter: {
        default: false,
        type: Boolean
    },
    overflowVisible: {
        default: false,
        type: Boolean
    },
    noDrag: {
        default: false,
        type: Boolean
    },
    showArrows: {
        default: false,
        type: Boolean
    },
    showDots: {
        default: false,
        type: Boolean
    },
    products: {
        default() {
            return []
        },
        type: Array
    },
    isList: {
        default: true,
        type: Boolean
    }
  }
}
</script>

<style scoped>

</style>