<!-- eslint-disable -->
<template>
  <div id="campaign-carousel-wrapper">
    <ssr-carousel
        :responsive="responsive"
        :slides-per-page="slidesPerPage"
        :gutter="gutter"
        :paginate-by-slide="paginateBySlide"
        :pagination-label="paginationLabel"
        :autoplay-delay="autoplayDelay"
        :loop="loop"
        :center="center"
        :peek="peek"
        :peek-left="peekLeft"
        :peek-right="peekRight"
        :peek-gutter="peekGutter"
        :overflow-visible="overflowVisible"
        :no-drag="noDrag"
        :show-arrows="showArrows"
        :show-dots="showDots"
    >
        <div class="slide" v-for="item in campaigns" :key="item.$index">
            <router-link :to="{ name: 'CampaignPage', params: { slug: item.slug }}">
                <img :src="item.asset.path" width="100%">
            </router-link>    
        </div>
    </ssr-carousel>
  </div>
</template>

<script>
export default {
  name: 'CampaignCarousel',
  data() {
    return {
        responsive: [
        {
          maxWidth: 1280,
          slidesPerPage: 3,
        },
        {
          maxWidth: 1024,
          slidesPerPage: 2,
        },
        {
          maxWidth: 767,
          slidesPerPage: 1
        }
      ]
    }
  },
  props: {
    campaigns: {
        default() {
            return []
        },
        type: Array
    },
    slidesPerPage: {
        default: 3,
        type: Number
    },
    gutter: {
        default: 20,
        type: Number
    },
    paginateBySlide: {
        default: false,
        type: Boolean
    },
    paginationLabel: {
        default: null,
        type: Text
    },
    autoplayDelay: {
        default: 0,
        type: Number
    },
    loop: {
        default: false,
        type: Boolean
    },
    center: {
        default: false,
        type: Boolean
    },
    peek: {
        default: 0,
        type: Number
    },
    peekLeft: {
        default: 0,
        type: Number
    },
    peekRight: {
        default: 0,
        type: Number
    },
    peekGutter: {
        default: false,
        type: Boolean
    },
    overflowVisible: {
        default: false,
        type: Boolean
    },
    noDrag: {
        default: false,
        type: Boolean
    },
    showArrows: {
        default: false,
        type: Boolean
    },
    showDots: {
        default: false,
        type: Boolean
    },
  }
}
</script>

<style scoped>
.slide img {
    border-radius: 5px;
}
</style>