<template>
  <div class="brands-carousel container" v-if="brands.length>0">
    <ssr-carousel
        :responsive="responsive"
        :slides-per-page="slidesPerPage"
        :gutter="gutter"
        :paginate-by-slide="paginateBySlide"
        :pagination-label="paginationLabel"
        :autoplay-delay="autoplayDelay"
        :loop="loop"
        :center="center"
        :peek="peek"
        :peek-left="peekLeft"
        :peek-right="peekRight"
        :peek-gutter="peekGutter"
        :overflow-visible="overflowVisible"
        :no-drag="noDrag"
        :show-arrows="showArrows"
        :show-dots="showDots"
    >
        <div class="slide" v-for="item in brands" :key="item.$index">
          <router-link :to="{ name: 'ProductsPage', query: { brand: item.slug }}">
            <div class="brand-item slide">
                <div class="brand-logo">
                    <img :src="item.path" :alt="item.name">
                </div>
            </div>
          </router-link>
        </div>
    </ssr-carousel>
  </div>
  <BrandCarouselPlaceholder v-else />
</template>

<script>
import BrandCarouselPlaceholder from '@/components/BrandCarouselPlaceholder.vue'
export default {
  name: "BrandCarousel",
  components: { BrandCarouselPlaceholder },
  data() {
    return {
      brands: [],
      responsive: [
        {
          maxWidth: 1280,
          slidesPerPage: 7,
        },
        {
          maxWidth: 1024,
          slidesPerPage: 4,
        },
        {
          maxWidth: 767,
          slidesPerPage: 2
        }
      ]
    }
  },
  props: {
    slidesPerPage: {
        default: 7,
        type: Number
    },
    gutter: {
        default: 20,
        type: Number
    },
    paginateBySlide: {
        default: false,
        type: Boolean
    },
    paginationLabel: {
        default: null,
        type: Text
    },
    autoplayDelay: {
        default: 0,
        type: Number
    },
    loop: {
        default: false,
        type: Boolean
    },
    center: {
        default: false,
        type: Boolean
    },
    peek: {
        default: 0,
        type: Number
    },
    peekLeft: {
        default: 5,
        type: Number
    },
    peekRight: {
        default: 0,
        type: Number
    },
    peekGutter: {
        default: false,
        type: Boolean
    },
    overflowVisible: {
        default: false,
        type: Boolean
    },
    noDrag: {
        default: false,
        type: Boolean
    },
    showArrows: {
        default: false,
        type: Boolean
    },
    showDots: {
        default: false,
        type: Boolean
    },
    products: {
        default() {
            return []
        },
        type: Array
    },
    isList: {
        default: true,
        type: Boolean
    }
  },
  async mounted() {
    try {
      var brands = await this.$axios.get(`/web/main/home-page/brands?is_corporate=1`, {
        params: {}
      }) 
      this.brands = brands.data.responseData.brands
    }catch (error) {
      console.log(error)
    }
  },
}
</script>

<style scoped>
.title {
  font-size: 35px;
  color: var(--dark);
}
.brand-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.brand-logo img {
  max-width: 110px;
  max-height: 30px;
  filter: saturate(0%) brightness(70%) contrast(1000%);
}

.brand-item:hover .brand-logo img {
  filter: none;
  transition: .2s;
}

.brand-logo {
  width: 150px;
  height: 150px;
  border-radius: 150px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.brands-carousel h4 {
  text-align: center;
  margin-bottom: 50px;
}

.brands-carousel {
  margin-top: 100px;
  margin-bottom: 100px;
}

.brand-content h5 {
  font-weight: 900;
  font-size: 16px;
}

.brand-content p {
  font-size: 14px;
  font-weight: 300;
}
</style>
 