<template>
  <div id="header-black-wrapper">
    <div class="container" id="header-black">

        <div class="logo-wrapper">
            <i class="fa-solid fa-bars" @click="$root.$emit('bv::toggle::collapse', 'mobile-menu')"></i>
            <router-link to="/">
                <img alt="Servislet Kurumsal" src="@/assets/servislet-kurumsal-logo.png">
            </router-link>
        </div>
        <div class="navigation">
            <ul>
                <li><router-link to="/lastik/urunler">Ürünler</router-link></li>
                <li><router-link to="/lastik/kampanyalar">Kampanyalar</router-link></li>
            </ul>
        </div>
        <div class="header-actions">
            <button v-b-toggle.tire-finder-sidebar-right>
                <img src="@/assets/icons/search.png">
            </button>
            <button class="m-0" style="margin-right: 1rem !important; position: relative;" @click="showCart">
                <img src="@/assets/icons/shoping-basket.png">
                <span class="cart-count" v-if="getUserCartItems.userCart && getUserCartItems.userCart.cartItems.length>0">{{ getUserCartItems.userCart.cartItems.length }}</span>
            </button>
            <!-- <button class="m-0" style="color: #f4f4f4;" @click="openCityBar">
                <span v-if="Object.keys(selectedCity).length>0">{{selectedCity.plate_code}}</span>
                <span v-else>ŞEHİR</span>
            </button> -->
            <div class="header-user-wrapper" v-b-toggle.profile-sidebar>
                <div>
                    <img :src="corporate_img" v-if="corporate_img">
                    <div v-else class="corporate-name">{{corporate_name}}</div>
                    <p>Hoş Geldiniz!</p>
                </div>
                <div class="ml-4">
                    <img src="@/assets/icons/corporate.png">
                </div>
            </div>
        </div>
    </div>
    <UserSidebar/>
    <MobileMenu/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserSidebar from '@/components/UserSidebar.vue'
import MobileMenu from '@/components/MobileMenu.vue'
import { EventBus } from '@/plugins/event-bus.js';

export default {
  name: 'HeaderBlack',
  components: { UserSidebar, MobileMenu },
  data() {
    return {
        corporate_img: JSON.parse(localStorage.userData).corporate.asset ?  JSON.parse(localStorage.userData).corporate.asset.path : null,
        corporate_name: JSON.parse(localStorage.userData).corporate.name
    }
  },
  computed: {
    ...mapGetters(['selectedCity', 'getUserCartItems']),
  },
  watch: {
    getUserCartItems(newValue) {
        // this.listUserCartItems()
    },
  },
  created() {
    EventBus.$on('custom-event', this.listUserCartItems);
  },
  beforeDestroy() {
    EventBus.$off('custom-event', this.listUserCartItems);
  },
  mounted() {
    this.listUserCartItems()
  },
  methods: {
    ...mapActions(['listUserCartItems']),
    showCart() {
        this.$root.$emit('bv::toggle::collapse', 'cart-sidebar')
    },
    openCityBar() {
      this.$root.$emit('bv::toggle::collapse', 'city-sidebar')
    },
  }
}
</script>

<style scoped>
.corporate-name{
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cart-count {
    position: absolute;
    top: 0px;
    right: -10px;
    background: #06a5d0;
    color: #f4f4f4;
    border-radius: 20px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}
#header-black-wrapper {
    background-color: #1c1c1c;
    color: #f4f4f4;
    padding: 1rem 0;
}
#header-black {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#header-black-wrapper img {
    width: 100%;
}
.navigation ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    width: 100%;
    font-weight: 600;
}
.navigation li {
    padding: 0 1rem;
}
.navigation ul a {
    color: inherit;
    text-decoration: none;
}
.header-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-user-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 2rem;
    background: #272727;
    padding: 0.35rem 1.5rem;
    border-radius: 5px;
    cursor: pointer;
}
.header-user-wrapper p {
    margin: 0;
    font-size: 12px;
    padding-top: .25rem;
}
.header-actions button {
    background: #272727;
    transition: .5s;
    border: 1px solid transparent;
    outline: none !important;
    padding: 12px;
    margin-right: 1rem;
    border-radius: 100px;
}
.header-user-wrapper img {
    max-width: 55px;
}
.ml-4 {
    margin-left: 1rem;
}
@media screen and (max-width: 600px) {
    .navigation {
        display: none;
    }
    .header-actions {
        width: 100%;
        margin-top: 1rem;
    }
    .logo-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-direction: row-reverse;
    }
    #header-black {
        flex-direction: column;
    }
}
</style>
