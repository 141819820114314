/* eslint-disable */
<template>
  <div class="megasearch">
    <span class="title">GENİŞLİK</span>
    <div class="widths mt-4">
      <div class="inputs">
        <div>
          <div class="megasearch-input neu-black" @click="focusInput">
            <input type="text" maxlength="3" v-model="search" ref="focus" v-mask="'###'" v-on:keyup="controlWidth">
          </div>
          <div class="list">
            <div class="list-items">
              <div class="item-list">
                <span v-for="width in filteredWidth" :key="width" class="cursor-pointer" @click="setWidth(width)">{{ width }}</span>
              </div>
            </div>
            <div class="list-content">
              <div class="content neu-black">
                <div class="content-title">
                  <img src="https://v3-main-demo.servislet.com/icons/width.png" alt="Yükseklik" width="30" class="mr-2">
                  <span>GENİŞLİK</span>
                </div>
                <span class="description">
                  İlk üç sayı bize milimetre cinsinden lastiğin kesit genişliğini söyler. Aşağıdaki örnekte, doğru şekilde takıldığında iç yan duvarın en geniş noktasından dış yan duvarın en geniş noktasına kadar ölçülen lastik genişliği 255 milimetredir.
                </span>
              </div>
            </div>
          </div>
          <!-- <div class="list-placeholder" v-else>
            <b-skeleton width="20%" v-for="placeholder in 20" :key="placeholder.$index" />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "SizeMegaSearchWidth",
  data() {
    return {
      search: ''
    }
  },
  computed: {
    ...mapGetters(['getSizeWidthList']),
    widths() {
      return this.getSizeWidthList
    },
    steps() {
      return this.$store.state.megaSearchSteps.size
    },
    filteredWidth() {
      if (this.search.trim().length === 0) {
        return this.getSizeWidthList
      } else {
        return this.getSizeWidthList.filter(item => item.toString().toLocaleUpperCase('tr-TR').indexOf(this.search.toLocaleUpperCase('tr-TR')) > -1)
      }
    },
  },
  methods: {
    ...mapActions(['listSizeWidths']),
   controlWidth() {
      if (this.search.length === 3) {
        if (this.getSizeWidthList.includes(parseInt(this.search))) {
          this.setWidth(parseInt(this.search))
        }
      }
    },
    focusInput() {
      this.$refs.focus.focus()
    },
    setWidth(width) {
      this.search = width.toString()
      this.$store.commit('megaSearchSetWidthForSize', this.search)
      this.$store.commit('increaseMegaSearchSizeStep')
    }
  },
  mounted() {
    this.$refs.focus.focus()
    this.listSizeWidths()
  }
}
</script>

<style scoped>

.title {
  font-size: 12px;
  font-weight: 700;
  color: var(--dark-white);
}

.megasearch-input {
  display: flex;
  flex-direction: column;
  padding: 15px 18px;
}

.megasearch-input input{
  border: none;
  height: 50px;
  font-size: 30px;
  background-color: var(--dark);
  color: var(--servislet);
  font-weight: 900;
}

.cursor {
  display: flex;
  gap: 5px;
}

.cursor span {
  height: 5px;
  background-color: var(--gray);
  width: px;
}

cursor .active {
  background-color: var(--servislet);
}

.list {
  display: flex;
  gap: 20px;
}

.list-items {
  width: 100%;
}

.item-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 30px;
  padding: 30px 20px;
  font-weight: 900;
  color: var(--light-gray);
  max-height: 50vh;
  overflow: auto;
  width: 100%;
}

.item-list::-webkit-scrollbar {
  display: none;
}

.list-content {
  margin-top: 30px;
  width: 100%;
}

.content {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-weight: 700;
  line-height: 1.4;
}

.content-title {
  display: flex;
  align-items: center;
}

.img-content {
  position: fixed;
  bottom: 0;
  right: 8%;
}

.list-placeholder {
  margin-top: 30px;
}

.content .description{
  font-weight:200;
  font-size:14px;
}

@media only screen and (max-width: 1024px) {
  .list-content {
    display: none;
  }

  .megasearch-input {
    padding: 10px;
  }

  .megasearch-input input{
    border: none;
    height: 50px;
    font-size: 25px;
    background-color: var(--dark);
    color: var(--servislet);
    font-weight: 900;
    letter-spacing: 21px;
    padding-left: 5px;
  }

  .seperator {
    font-size: 25px;
  }

  .cursor span {
    width: 30px;
  }
}

@media only screen and (max-width: 580px) {
  .item-list {
    padding-left: 10px;
  }

  .list-placeholder {
    padding-left: 10px;
  }
}
</style>
