import { render, staticRenderFns } from "./ProductsCarouselPlaceholder.vue?vue&type=template&id=6eb7bfc7&scoped=true&"
import script from "./ProductsCarouselPlaceholder.vue?vue&type=script&lang=js&"
export * from "./ProductsCarouselPlaceholder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eb7bfc7",
  null
  
)

export default component.exports