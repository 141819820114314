<template>
  <!-- <NuxtLink :custom="true" :to="localePath({name: 'tire-product', params: { brand: product.brand_slug, slug: product.slug }, query: { shop: Object.keys(product.branch).length > 0 ? product.branch.slug : '' }})"> -->
  <router-link
      :to="{ name: 'ProductPage', params: { brand: product.brand_slug, slug: product.slug, shop: product.branch ? product.branch.slug : ' ' }}">
    <span @click="setMetaTitle" v-if="product">
      <div class="product-card neu text-center">
        <div class="tags">
            <div class="tag" v-if="product.has_campaign"><span>KAMPANYA</span></div><!-- v-if="product.has_campaign" -->
        </div>
        <div class="slide" v-if="product.categories && product.categories[0].attachments && product.categories[0].attachments.length > 0">
          <div class="mousehover">
              <div v-for="(image,key) in product.categories[0].attachments" :key="key">
                  <span @mouseover="changeSlide(key)" v-if="key < 4"></span>
              </div>
          </div>
          <div style="height: 170px">
              <div v-for="(image,key) in product.categories[0].attachments" :key="key">
                  <img :alt="product.name" v-if="xCoor === key && key < 4 && image.path" :src="image.path" width="170"
                       id="image-slide">
              </div>
          </div>
      </div>
      <div class="slide" v-else>
        <img :src="product.assets[0]" width="170" :alt="product.name" id="image-slide" v-if="product.assets && product.assets.length>0">
        <img :src="product.assets.path" width="170" :alt="product.name" id="image-slide" v-if="product.assets && Object.keys(product.assets).length >0">
        <img :src="product.asset" width="170" :alt="product.name" id="image-slide" v-else-if="product.asset">
        <img src="@/assets/tire-no-img.png" alt="No Img" width="140" v-else>
      </div>
      <div class="dots">
        <!-- <span @click="xCoor = key" v-for="(image,key) in product.categories[0].attachments" :class="xCoor === key ? 'dot active' : 'dot'" v-if="key < 4"></span> -->
      </div>
      <div class="product-card-content" :class="!isPopuler ? 'product-list-card' : ''">
          <span class="car-type text-center bold" v-if="car_type === true && !isList">Binek Araç</span>
          <span class="product-brand mt-2"><span
              class="bolder">{{ product.brand ? product.brand.name : product.brand_name }}</span> {{
              product.name
            }}</span>
          <span class="mt-3" id="product-card-brand-name"
                v-if="product.brand && Object.keys(product.brand).length > 0 && brand_logo === true"></span>
            <div class="product-card-rate"> <!-- mt-2 -->
                <div class="driverreviews-widget" :data-ean="product.barcode" data-type="rating"></div>
            </div>
          <div class="product-card-price w-100"> <!-- v-if="product.price" -->
              <div class="campaign-tooltip"> <!-- v-if="product.has_campaign" -->
                  <span class="campaign-txt"
                        v-if="Object.keys(selectedCity).length>0 && product.show_price !== false && product.has_campaign">KAMPANYA DAHİL FİYAT</span>
                <!-- <div v-if="Object.keys(city).length !== 0">
                    <div v-if="product.price > product.discount_price && product.discount_price" class="d-flex flex-column price-area">
                    <span class="amount">{{ product.discount_price !== null ? product.discount_price : '9999.99 TL' }}</span>
                    </div>
                    <div v-else class="price-area"><span class="amount">{{ product.price !== null ? product.price : '9999.99 TL'  }}</span></div>
                </div>
                <div class="select-city-for-prices" v-if="!product.show_price" @click.prevent="openCityBar">
                    <button>ŞEHİR SEÇ</button>
                </div> -->
                  <div class="price-area"><span class="amount">{{
                      product.price !== null ? formatPrice(product.price) : formatPrice(9999)
                    }}</span></div>
                <!-- <div class="select-city-for-prices" v-else  @click.prevent="openCityBar">
                    <button>ŞEHİR SEÇ</button>
                </div> -->
              </div>
          </div>
          <div class="product-card-campaign-badges" style="visibility: hidden;">
            <span class="product-card-badge"> <i class="fas fa-wrench"></i> Montaj Dahil </span>
            <span class="product-card-badge"> <i class="fa fa-tag"></i> Promosyon</span>
          </div>
        </div>
      </div>
    </span>
    <span v-else>
        <div class="product-card neu text-center p-3">
            <content-placeholders :rounded="true">
                <content-placeholders-img/>
                <content-placeholders-heading/>
            </content-placeholders>
        </div>
    </span>
  </router-link>
  <!-- </NuxtLink> -->
</template>

<script>
/* eslint-disable */
// import StarRateComponent from "@/components/shared/StarRate";
import {mapGetters, mapActions} from 'vuex';

export default {
  name: "TireProductCard",
  // components: {StarRateComponent},
  props: {
    product: {
      type: Object,
      default: () => {
      }
    },
    isPopuler: {
      type: Boolean,
      default: false
    },
    car_type: {
      type: Boolean,
      default: true
    },
    brand_logo: {
      type: Boolean,
      default: true
    },
    isList: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formatPrice(price) {
      if (price) {
        return price.toLocaleString('tr-TR', {
          style: 'currency',
          currency: 'TRY',
        });
      }
    },
    changeSlide(item) {
      this.xCoor = item
    },
    openCityBar() {
      this.$root.$emit('bv::toggle::collapse', 'city-sidebar')
    },
    setMetaTitle() {
      return false
      document.title = this.product.meta_title + ' | Servislet'
    },
    // refresh() {
    //   driverreviews.isRefreshing = false
    //   driverreviews.refreshWidgets();
    // },
  },
  computed: {
    ...mapGetters(['selectedCity']),
  },
  data() {
    return {
      xCoor: 0,
      direction: "",
      responsive: [
        {
          maxWidth: 1280,
          slidesPerPage: 1,
        },
        {
          maxWidth: 1024,
          slidesPerPage: 1,
        },
        {
          maxWidth: 767,
          slidesPerPage: 1
        }
      ]
    }
  },
}
</script>

<style scoped>
a {
  color: inherit !important;
  text-decoration: unset;
}

.product-badge .fa-tag {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.product-card-badge:first-child {
  margin-left: 0px !important;
}

.product-card-badge i {
  padding-right: 6px;
}

.product-card-badge {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  border: 1px solid;
  border-radius: 5px;
  padding: 6px;
  font-weight: 700;
  margin-left: 12px;
  /* white-space: nowrap; */
}

.product-card-campaign-badges {
  margin-top: 36px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.campaign-txt {
  color: #1c1c1c;
  font-size: 9px;
  font-weight: 400;
}

.rate-count {
  opacity: 1;
  font-size: 12px;
  margin-left: 5px;
}

.opacity-04 {
  opacity: .4 !important;
  font-size: 10px;
}

.select-city-for-prices {
  text-align: center;
  bottom: 2rem;
  width: 100%;
  margin-bottom: 20px;
}

.select-city-for-prices button {
  background: #1c1c1c;
  transition: .5s;
  color: #f4f4f4;
  outline: none;
  border: none;
  font-size: 12px;
  padding: 10px 16px;
  width: 100%;
  border-radius: 5px;
}

.select-city-for-prices button:hover {
  transition: .5s;
  background: #06a5d0;
  border-color:   #06a5d0;
}

.price-area {
  height: 30px;
  margin-top: 6px;
}

.nuxt-link-active {
  color: inherit !important;
}

.product-card {
  margin-top: 100px;
  transition: .5s;
  border: 1px solid transparent;
  cursor: pointer;
  margin-bottom: 50px;
  min-height: 300px;
  position: relative;
  height: 100%;
  /* max-height: 360px; */
  max-height: 300px;
}

.product-card:hover {
  border: 1px solid #06a5d0;
  box-shadow: none;
  transition: .5s;
}

.product-card-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*
.product-card-image img {
  margin-top: -90px;
}*/


.product-card-content {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.product-card-content h5 {
  font-weight: 700;
}

.car-type {
  border-radius: 5px;
  padding: 10px;
  color: var(--dark);
  background-color: var(--dark-white);
  font-size: 12px;
  margin-bottom: 15px;
}

.tags {
  position: absolute;
  font-size: 10px;
  z-index: 1;
  font-weight: 700;
  text-transform: uppercase;
  top: -90px;
  left: 0px;
  margin-left: 0px !important;
  gap: 7px;
  display: flex;
  flex-direction: column;
}

.tag {
  background-color: #06a5d0;
  border-radius: 4px;
  color: #fff;
  padding: 5px 12px;
}

.tag-black {
  background-color: var(--dark);
  border-radius: 3px;
  color: #fff;
  padding: 5px 12px;
}

.product-card-price {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  line-height: 18px;
}

.product-card-content h4 {
  font-size: 16px;
}

.campaign-tooltip {
  font-size: 8px;
  font-weight: 700;
  margin-bottom: 4px;
  height: 8px;
}

.campaign-tooltip i {
  margin-left: 5px;
  color: #06a5d0;
}

.amount {
  font-size: 32px;
  font-weight: 900;
}

.line-through {
  text-decoration: line-through;
  color: var(--light-gray);
  font-size: 13px;
  font-weight: 300;
}

.product-brand {
  font-size: 16px;
  overflow: hidden;
  display: -webkit-box;
  height: 36px;
  font-weight: 400;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 20px;
}

#product-card-brand-name {
  font-weight: 900;
}

.brand-image {
  padding: 0 40px 10px 40px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--gray);
}

.dot.active {
  background-color: var(--dark);
}

.slide {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -90px;
}


.approve-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
}

.approve-button {
  font-size: 13px;
  background-color: var(--dark);
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff;
  width: 100%;
}

.product-card-rate {
  margin-top: 0;
  height: 70px;
  margin-bottom: 8px;
}

@media only screen and (max-width: 580px) {
  .amount {
    font-size: 25px;
  }

  .product-card-rate-mobile {
    height: 35px;
  }

  .product-card {
    height: 100%;
  }

  .product-card-rate {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    height: 80px;
  }


  .text-center {
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .product-brand {
    font-size: 12px;
    /* height: 48px; */
    height: 38px;
  }

  .product-card-campaign-badges {
    flex-direction: column;
    width: 100%;
  }

  .product-card-badge {
    margin-left: 0px;
    margin-top: 6px;
    width: 100%;
    justify-content: center !important;
  }

  .product-card {
    max-height: 400px !important;
  }

  .slide img {
    width: 160px;
  }
}
</style>

<style>
.product-card .ssr-carousel-dot-icon {
  width: 10px !important;
  height: 10px;
  border: none;
}

.product-card .ssr-carousel-dot-button:disabled .ssr-carousel-dot-icon {
  background-color: var(--dark);
}

.product-card .ssr-carousel-dot-button .ssr-carousel-dot-icon {
  background-color: var(--gray);
}

.dots {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 10px;
  margin-top: -80px;
}


.mousehover {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  height: 170px;
  align-items: center;
  justify-content: center;
}

.mousehover span {
  width: 55px;
  height: 145px;
}

.product-list-card {
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-start;
  text-align: left;
}

.montage {
  border: 1px solid #06a5d0;
  color: #06a5d0;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 13px;
  margin-bottom: 15px;
}
</style>
