<template>
  <servislet-overlay> <!-- :loading="loading.control" -->
    <template>
      <div>
        <header-black />
        <slot></slot>
      </div>
    </template>
    <CartSidebar />
    <CitySidebar />
    <TireFinderSidebarRight />
    <TireMegaSearchWithCar />
    <TireMegaSearchWithSize />
    <TireFooter />
  </servislet-overlay>
</template>

<script>
import ServisletOverlay from "@/components/ServisletOverlay";
import HeaderBlack from "@/components/HeaderBlack";
import TireFinderSidebarRight from "@/components/TireFinderSidebarRight";
import TireMegaSearchWithCar from "@/components/TireMegaSearchWithCar";
import TireMegaSearchWithSize from "@/components/TireMegaSearchWithSize";
import TireFooter from "@/components/TireFooter";
import CartSidebar from '@/components/CartSidebar.vue'
import CitySidebar from '@/components/CitySidebar.vue'

// import CarAddSidebar from "@/components/shared/CarAddSidebar.vue";
// import SmartBanner from "~/components/shared/SmartBanner";

export default {
  name: "DefaultLayout",
  components: { ServisletOverlay, HeaderBlack, TireFinderSidebarRight, TireMegaSearchWithCar, TireMegaSearchWithSize, TireFooter, CartSidebar, CitySidebar },
  mounted(){
    function initialize(i, t) {
      var e;
      i.getElementById(t) ? initFreshChat() : ((e = i.createElement("script")).id = t, e.async = !0, e.src = "https://wchat.freshchat.com/js/widget.js", e.onload = initFreshChat, i.head.appendChild(e))
    }

    function initiateCall() {
      initialize(document, "Freshchat-js-sdk")
    }

    window.addEventListener ? window.addEventListener("load", initiateCall, !1) : window.attachEvent("load", initiateCall, !1);
    openFresh()
    function openFresh() {
      if (window.fcWidget) {
        window.fcWidget.show();
      }
    }
  }
}
</script>
