<template>
  <div class="megasearch">
    <span class="title">POPÜLER ARAÇ MARKALARI</span>
    <div class="brand-row">
      <div class="brands mt-4">
        <div class="brand" v-for="populer in populers" :key="populer.$index" @click="setBrand(populer)">
          <span class="brand-img"><img :src="populer.asset" alt="" width="50"></span>
          <span class="brand-name">{{ populer.name }}</span>
        </div>
      </div>
      <div class="flex-top-space">
        <div class="w-100">
            <div class="mt-3"> <!-- brand-list-content mt-3 neu-black br-50 w-100 -->
                <div class="form-black">
                  <div class="form-group">
                    <input type="text" v-model="search" required="required" placeholder="Marka arayınız" value="" class="form-control dark-input city-megabar-input neu-black">
                    <img src="@/assets/icons/search-ico.png">
                  </div>
                </div>
            </div>
            <div class="brand-list-content mt-3 neu-black p-4 w-100" style="padding-top:0 !important;" v-if="filteredBrand.length > 0">
                <div class="brand-list mt-3">
                  <div class="brand-list-item" v-for="brand in filteredBrand" :key="brand.$index" @click="setBrand(brand)">
                      <span class="bold">{{ brand.name }}</span>
                  </div>
                </div>
            </div>
            <div class="brand-list-content mt-3 neu-black p-4 w-100" style="padding-top:0 !important;" v-else>
                <div class="brand-list mt-3">
                  <content-placeholders :rounded="true">
                    <content-placeholders-heading />
                    <content-placeholders-text :lines="6" />
                  </content-placeholders>
                </div>
            </div>
        </div>
        <div class="w-100">
            <!-- <div class="brand-list-content mt-3 neu-black p-4 search-history-wrapper" style="padding-top:15px !important;">
              <div class="flex-center-space">
                <span class="title">ÖNCEKİ ARAMALAR</span>
                <span class="delete-sh">Tümünü Sil</span>
              </div>
                <div class="brand-list mt-2">
                <div class="brand-list-item">
                    <span class="bold">VW Passat 2017 Elegance </span> <i class="fa fa-plus"></i>
                </div>
                </div>
            </div> -->
        </div>
      </div>
      <!-- <div class="list-placeholder" v-else>
        <b-skeleton width="30%" v-for="placeholder in 20" :key="placeholder.$index" />
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "CarMegaSearchBrand",
  data() {
    return {
      search: '',
      populers: [
        { "car_brand_id": 21, "name": "FIAT", "asset" : "/img/car/fiat.png"},
        { "car_brand_id": 48, "name": "RENAULT", "asset" : "/img/car/renault.png"},
        { "car_brand_id": 23, "name": "FORD", "asset" : "/img/car/ford.png"},
        { "car_brand_id": 65, "name": "VOLKSWAGEN", "asset" : "/img/car/volkswagen.png"},
        { "car_brand_id": 25, "name": "HYUNDAI", "asset" : "/img/car/hyundai.png"},
        { "car_brand_id": 63, "name": "TOYOTA", "asset" : "/img/car/toyota.png"},
        { "car_brand_id": 18, "name": "DACIA", "asset" : "/img/car/dacia.png"},
        { "car_brand_id": 45, "name": "PEUGEOT", "asset" : "/img/car/peugeot.png"},
      ]
    }
  },
  /* mounted() {
    this.$store.dispatch('shared/car/getBrandList')
  }, */
  computed: {
    ...mapGetters(['getBrandList']),
    filteredBrand() {
      if (this.search.trim().length === 0) {
        return this.getBrandList
      } else {
        return this.getBrandList.filter(brand => brand.name.toLocaleUpperCase('tr-TR').indexOf(this.search.toLocaleUpperCase('tr-TR')) > -1)
      }
    },
  },
  mounted() {
    this.listBrands()
  },
  methods: {
    ...mapActions(['listBrands']),
    setBrand(brand) {
      this.$store.commit('megaSearchSetBrand', brand)
      this.$store.commit('increaseMegaSearchCarStep')
    }
  }
}
</script>

<style scoped>
.search-history-wrapper {
  padding-top: 0px !important;
  margin-left: auto;
  width: 60%;
}
.search-history-wrapper .title {
  color: #777;
  font-size: 14px;
  font-weight: 800;
}
.search-history-wrapper .brand-list-item {
  border: none;
}
.search-history-wrapper .brand-list-item .fa {
  color: #777;
}
 .delete-sh {
  color: #777;
  text-decoration: underline;
}
.form-group {
  position: relative;
}
.form-group img {
  position: absolute;
  right: 1.5rem;
  top: 15px;
  width: 100%;
  max-width: 26px;
}

.flex-top-space {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.city-megabar-input{
  border: none;
  outline: none;
  height: 50px;
  background-color: #1c1c1c !important;
  border-radius: 50px !important;
  padding: 1.5rem;
}
.brand-row {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.megasearch {
  height: auto;
}
.title {
  font-size: 12px;
  font-weight: 700;
  color: var(--dark-white);
}

.brands {
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: left;
  flex: 9;
  overflow-x: scroll;
  overflow-y: hidden;
}

.brands::-webkit-scrollbar {
  display: none;
}

.brand {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.brand-img {
  background-color: var(--light);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 900;
  border: 5px solid #252525;
  width: 6rem;
  height: 6rem;
}

.brand:hover .brand-img {
  border: 5px solid var(--servislet);
}

.brand:hover .brand-img, .brand:hover .brand-name {
  color: var(--servislet);
}

.brand-name {
  font-weight: 700;
  margin-top: 5px;
  font-size: 13px;
}

.brand-megabar-input {
  height: 50px;
}

.brand-list {
  max-height: 49vh;
  overflow-y: scroll;
}

.brand-list-content {
  max-height: 50vh;
  width: 50%;
  flex: 5;
  position: relative;
  overflow: hidden;
}

.brand-list::-webkit-scrollbar {
  display: none;
}

.brand-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
  border-color: var(--dark-gray);
  padding: 15px 5px;
  cursor: pointer;
  text-transform: uppercase;
}

.brand-code {
  background-color: var(--dark-gray);
  padding: 7px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .brands {
    display: none;
  }

  .brand-list-content {
    width: 100%;
  }
}
</style>