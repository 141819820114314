<template>
  <b-sidebar id="tire-finder-sidebar-right" title="Lastik Bulucu" shadow right backdrop no-header text-variant="light">
    <div class="sidebar-close-button" @click="closeBar">
      <img src="/img/icons/closebutton.svg" alt="Close" width="20">
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <span class="sidebar-title">Lastik Bulucu</span>
        <!-- <input type="text" id="sidebar-search-input" class="neu-black"> -->
        <div class="mt-4">
          <div class="steps mt-4">
            <div class="step hovered" @click="getSearchBar(0)">
              <span class="font-light">Araca Göre</span>
              <i class="fa fa-angle-right "></i>
            </div>
            <div class="step hovered" @click="getSearchBar(1)">
              <span class="font-light">Ebata Göre</span>
              <i class="fa fa-angle-right "></i>
            </div>
          </div>
        </div>
      </div>
      <div class="button-wrapper">
        <!-- <button>Ürün Ara</button> -->
      </div>
    </div>
  </b-sidebar>
</template>

<script>
export default {
  name: "TireFinderSidebarRight",
  data() {
    return {
      step: 0
    }
  },
  methods: {
    closeBar() {
      this.$root.$emit('bv::toggle::collapse', 'tire-mega-search-with-shared')
    },
    getSearchBar(value) {
      if (value === 0) {
        this.$root.$emit('bv::toggle::collapse', 'tire-mega-search-with-car')
      }

      if (value === 1) {
        this.$root.$emit('bv::toggle::collapse', 'tire-mega-search-with-size')
      }
    }
  }
}
</script>

<!--<style>
#tire-mega-search-with-shared {
  width: 100% !important;
}
</style>-->

<style scoped>
.active-span:hover{
  color: var(--servislet) !important;
  opacity: 1;
}
.title {
  font-size: 12px;
  font-weight: 700;
  color: var(--dark-white);
}

.steps {
  display: flex;
  gap: 30px;
  align-items: flex-start;
  flex-direction: column;
}
.servislet-blue{
  color: #06a5d0;
  font-weight: 600;
}
.step {
  display: flex;
  gap: 20px;
  cursor: pointer;
  font-size: 25px;
  font-weight: 700;
  align-items: center;
  width: 100%;
  border-radius: 10px;
}

.step:hover .step-img {
  border: 5px solid var(--servislet);
}

.step:hover span {
  color: var(--servislet);
}

.search {
  margin-top: 20px;
}

.divider {
  width: 100%;
  height: 2px;
  background-color: var(--gray);
  margin-top: 50px;
}

.search-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.sidebar-title {
  font-size: 25px;
  font-weight: 900;
}

#sidebar-search-input {
  width: 100%;
  margin-top: 1.5rem;
  border-radius: 100px;
  outline: none;
  border: none;
  padding: 1rem;
}

button {
  background: #06a5d0;
  color: #f4f4f4;
  padding: 1rem;
  width: 100%;
  border: 1px solid #06a5d0;
  outline: none !important;
  border-radius: 5px;
  position: fixed;
  bottom: 2rem;
  max-width: 440px;
  transition: .5s;
}
button:hover {
  background: #1c1c1c;
  transition: .5s;
}
</style>