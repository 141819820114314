<template>
  <b-sidebar id="city-sidebar" title="Şehir Seç" shadow right backdrop text-variant="light" class="rounded" no-header>
    <div class="sidebar-close-button" @click="closeBar">
      <img src="/img/icons/closebutton.svg" alt="Close" width="20">
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <span class="sidebar-title">Şehir Seç</span>
        <p class="mt-3 city-text">İhtiyacınız olan lastiği bulunduğunuz şehirde size en yakın servis noktasından satın almak için şehir seçimi yapmanız gerekir.</p>
        <p class="city-sidebar-text ">Detaylı bilgi için: <span class="text-underline servislet-blue">
          <!-- <NuxtLink :to="localePath({name: 'tire-howdoesitwork'})" class="active-span">Nasıl Çalışır?</NuxtLink> -->
          <router-link to="/" class="active-span">Nasıl Çalışır?</router-link>
        </span>
        </p>
        <div class="city-sidebar-search">
          <div class="form-black">
            <div class="form-group">
              <input type="text" class="form-control dark-input" required v-model="search" placeholder="Şehir seç">
            </div>
          </div>
        </div>
        <ul class="city-list">
          <li class="d-flex justify-content-between" v-for="city in filteredCity" :key="city.$index"
              @click="selectCity(city); closeBar();">
            <span class="city-name">{{ city.name }}</span>
            <span class="city-code">{{ city.plate_code }}</span>
          </li>
        </ul>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "CitySidebarComponent",
  data() {
    return {
      search: '',
    }
  },
  computed: {
    ...mapGetters(['getCityList', 'selectedCity']),
    filteredCity() {
      if (this.search.trim().length === 0) {
        return this.getCityList
      } else {
        return this.getCityList.filter(item => item.name.toLocaleUpperCase('tr-TR').indexOf(this.search.toLocaleUpperCase('tr-TR')) > -1)
      }
    }
  },
  mounted() {
    this.listCities()
  },
  methods: {
    ...mapActions(['listCities', 'selectCity']),
    closeBar() {
      this.$root.$emit('bv::toggle::collapse', 'city-sidebar')
    }
  }
}
</script>

<style scoped>
.form-black input {
    background: rgba(97,97,97,0.30196);
    border-color: rgba(97,97,97,0.30196);
}
.active-span:hover{
  color: var(--servislet) !important;
  opacity: 1;
}
.city-text {
  color: #CCC !important;
}
.servislet-blue {
  color: #06a5d0;
  font-weight: 600;
}
.city-sidebar-search .form-group {
  margin-bottom: 0px;
}

.city-sidebar-text {
  line-height: 1.4 !important;
  color: var(--light);
}

.city-sidebar-text a {
    color: inherit;
}
.city-sidebar-search {
  margin-top: 40px;
}

.city-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 20px;
  height: 50vh;
  overflow-y: scroll;
}

.city-list::-webkit-scrollbar {
  display: none;
}

.city-list li {
  border-bottom: 1px solid;
  border-color: var(--dark-gray);
  padding: 15px 5px;
  cursor: pointer;
}

.city-name {
  display: flex;
  justify-content: center;
  align-items: center;
}

.city-code {
  background-color: var(--dark-gray);
  padding: 7px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  text-align: center;
}
</style>