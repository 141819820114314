<template>
  <default-layout>
    <div id="default-layout-wrapper">
      <HomeWelcome />
      <div class="container">
        <div class="home-campaing-wrapper" v-if="campaigns.length>0">
          <div class="flex-center-space">
            <h5>KAMPANYALAR</h5>
            <!-- <router-link to="/lastik/urunler">Tümünü Gör</router-link> -->
          </div>
          <CampaignCarousel :campaigns="campaigns" :showDots="true" />
        </div>
      </div>
      <!-- <HomeOffer class="mt-5" /> -->
      <!-- <div class="container" id="product-carousel-container">
        <div class="flex-center-space mt-5">
          <h4>Satın alma geçmişinize göre</h4>
          <div class="home-tab-btns">
            <button :class="{ active: summer }" @click="summer = true; winter = false; all_season = false">Yaz</button>
            <button :class="{ active: winter }" @click="winter = true; summer = false; all_season = false">Kış</button>
            <button :class="{ active: all_season }" @click="all_season = true; summer = false; winter = false">4 Mevsim</button>
          </div>
        </div>
        <div class="row mt-5">
          <ProductsCarousel :products="products" :is-list="true" v-if="products.length>0"/>
          <ProductsCarouselPlaceholder v-else />
        </div>
      </div> -->

      <div class="container mt-5" id="product-carousel-container"> <!-- v-if="best_seller_products.length>0"-->
        <div class="flex-center-space mt-5">
          <h4>Çok satanlar</h4>
          <div class="home-tab-btns">
            <button :class="{ active: summer_best_sellers }" @click="summer_best_sellers = true; winter_best_sellers = false; all_season_best_sellers = false">Yaz</button>
            <button :class="{ active: winter_best_sellers }" @click="winter_best_sellers = true; summer_best_sellers = false; all_season_best_sellers = false">Kış</button>
            <button :class="{ active: all_season_best_sellers }" @click="all_season_best_sellers = true; summer_best_sellers = false; winter_best_sellers = false">4 Mevsim</button>
          </div>
        </div>
        <div class="row mt-5">
          <ProductsCarousel :products="best_seller_products" :is-list="true" v-if="!best_seller_products_loader && best_seller_products.length>0"/>
          <div v-else-if="!best_seller_products_loader && best_seller_products.length === 0"></div>
          <ProductsCarouselPlaceholder v-if="best_seller_products_loader" />
        </div>
      </div>

      <!-- <div class="container mt-5" id="product-carousel-container">
        <div class="flex-center-space mt-5">
          <h4>En çok yorum alanlar</h4>
          <div class="home-tab-btns">
            <button :class="{ active: summer_top_reviewed }" @click="summer_top_reviewed = true; winter_top_reviewed = false; all_season_top_reviewed = false">Yaz</button>
            <button :class="{ active: winter_top_reviewed }" @click="winter_top_reviewed = true; summer_top_reviewed = false; all_season_top_reviewed = false">Kış</button>
            <button :class="{ active: all_season_top_reviewed }" @click="all_season_top_reviewed = true; summer_top_reviewed = false; winter_top_reviewed = false">4 Mevsim</button>
          </div>
        </div>
        <div class="row mt-5">
          <ProductsCarousel :products="top_reviewed_products" :is-list="true" v-if="top_reviewed_products.length>0"/>
          <ProductsCarouselPlaceholder v-else />
        </div>
      </div> -->

      <div class="container" id="brand-carousel-container">
        <h4>Markalar</h4>
        <BrandCarousel />
      </div>

      <ContactBand />
      <NewsletterBand />

    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default.vue'
import HomeWelcome from '@/components/HomeWelcome.vue'
import CampaignCarousel from '@/components/CampaignCarousel.vue'
// import HomeOffer from '@/components/HomeOffer.vue'
import ProductsCarousel from '@/components/ProductsCarousel.vue'
import ProductsCarouselPlaceholder from '@/components/ProductsCarouselPlaceholder.vue'
import BrandCarousel from '@/components/BrandCarousel.vue'
import ContactBand from '@/components/ContactBand.vue'
import NewsletterBand from '@/components/NewsletterBand.vue'
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'HomePage',
  components: { DefaultLayout, HomeWelcome, CampaignCarousel, ProductsCarousel, ProductsCarouselPlaceholder, BrandCarousel, ContactBand, NewsletterBand }, // HomeOffer
  data() {
    return {
      products: [],
      best_seller_products: [],
      best_seller_products_loader: false,
      top_reviewed_products: [],
      campaigns: [],
      summer: true,
      winter: false,
      all_season: false,
      summer_best_sellers: true,
      winter_best_sellers: false,
      all_season_best_sellers: false,
      summer_top_reviewed: true,
      winter_top_reviewed: false,
      all_season_top_reviewed: false,
    }
  },
  watch: {
    summer: 'getProducts',
    winter: 'getProducts',
    all_season: 'getProducts',
    summer_best_sellers: 'getBestSellerProducts',
    winter_best_sellers: 'getBestSellerProducts',
    all_season_best_sellers: 'getBestSellerProducts',
    summer_top_reviewed: 'getTopReviewedProducts',
    winter_top_reviewed: 'getTopReviewedProducts',
    all_season_top_reviewed: 'getTopReviewedProducts',
  },
  computed: {
    ...mapGetters(['selectedCity']),
  },
  mounted() {
    // this.getProducts()
    this.getCampaigns(),
    this.getBestSellerProducts()
    // this.getTopReviewedProducts()
  },
  methods: {
    async getProducts() {
      try {
        this.products = []
        var products = await this.$axios.get(`/web/main/c-tire/products`, {
          params: {
            page: 1,
            limit: 10,
            summer: this.summer ?? this.summer,
            winter: this.winter ?? this.winter,
            all_season: this.all_season ?? this.all_season,
          }
        })
        this.products = products.data.responseData.tireProducts.items
      }catch (error) {
        console.log(error)
      }
    },
    async getBestSellerProducts() {
      var season_id = 3
      if(this.summer_best_sellers) {
        season_id = 2
      } else if (this.winter_best_sellers) {
        season_id = 1
      } else {
        season_id = 3
      }
      try {
        this.best_seller_products_loader = true
        this.best_seller_products = []
        var products = await this.$axios.get(`/web/main/c-tire/products/best-sellers`, {
          params: {
            tire_season_id: season_id,
          }
        })
        this.best_seller_products = products.data.responseData.bestSellers
        this.best_seller_products_loader = false
      }catch (error) {
        console.log(error)
      }
    },
    async getTopReviewedProducts() {
      try {
        this.top_reviewed_products = []
        var products = await this.$axios.get(`/web/main/home-page/best-sellers`, {
          params: {
            brand_id: 1,
            tire_season_id: 2,
            city_id: this.selectedCity.id
          }
        })
        this.top_reviewed_products = products.data.responseData.bestSellers
      }catch (error) {
        console.log(error)
      }
    },
    async getCampaigns() {
      try {
        var campaigns = await this.$axios.get(`/web/main/post/campaign?app_type_code=tire&filter_where=is_slider:1&app_type_code=c-tire`, {
          params: {}
        })
        this.campaigns = campaigns.data.responseData.cmsPosts.items
      }catch (error) {
        console.log(error)
      }
    },
  }
   
}
</script>

<style scoped>
.mt-7 {
  margin-top: 7.5rem !important;
}
.home-campaing-wrapper {
  margin-top: 7.5rem;
}
.home-campaing-wrapper h5 {
  font-size: 15px;
  color: #1c1c1c;
  font-weight: 900;
  margin-bottom: 2rem;
}
.home-campaing-wrapper a {
  color: inherit;
  font-size: 13px;
  font-weight: 600;
  text-decoration: none;
  transition: .5s;
}
.home-campaing-wrapper a:hover {
  text-decoration: underline;
  transition: .5s;
}
.home-tab-btns button {
    background: #EAEAEA;
    color: #1c1c1c;
    border-radius: 50px;
    padding: .5rem 1rem;
    outline: none !important;
    border: 1px solid #EAEAEA;
    margin-right: 1rem;
    font-weight: 500;
    min-width: 120px;
}
.home-tab-btns button.active,
.home-tab-btns button:hover {
    background-color: #1c1c1c;
    border-color: #1c1c1c;
    color: #f4f4f4;
    transition: .5s;
}
.home-tab-btns button:last-child {
    margin-right: 0;
}
#product-carousel-container h4 {
  font-size: 35px;
  font-weight: 900;
}
#brand-carousel-container {
  text-align: center;
  margin-top: 50px;
}
#brand-carousel-container h4 {
  font-size: 35px;
  font-weight: 900;
}
</style>
