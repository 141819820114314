<template>
  <b-overlay
      id="overlay-background"
      variant="'transparent'"
      :show="loading"
      opacity="1"
      rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <img alt="Servislet" src="@/assets/servislet.gif">
      </div>
    </template>
    <slot></slot>
  </b-overlay>
</template>

<script>
export default {
  name: "ServisletOverlay",
  props: {
    loading: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style scoped>

</style>