<template>
  <div class="megasearch">
    <span class="title">MEVSİMLER</span>
    <div class="seasons mt-4">
      <div class="season" @click="setAllItems">
        <span class="season-logo" style="font-size: 14px">TÜMÜ</span>
        <span class="season-name"></span>
      </div>
      <div class="season" v-for="season in seasons" :key="season.$index" @click="setItem(season)" :class="selected.find(select => select.name === season.name) ? 'active' : ''">
        <span class="season-logo"><img class="img" :src="`img/icons/${season.code}.png`" :alt="season.name" width="40" height="auto"></span>
        <span class="season-name">{{ season.name.toUpperCase() }}</span>
      </div>
    </div>
    <div class="mt-5 space-between">
      <button class="btn bg-servislet" @click="nextPage()">Devam Et</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "SizeMegaSearchSeasonComponent",
  data() {
    return {
      selected: [],
    }
  },
  computed: {
    ...mapGetters(['getSizeSeasonList']),
    seasons() {
      return this.getSizeSeasonList
    },
    steps() {
      return this.$store.state.megaSearchSteps.size
    },
  },
  mounted() {
    this.listSizeSeasons()
  },
  methods: {
    ...mapActions(['listSizeSeasons']),
    nextPage() {
      if(this.$store.state.megaSearchDataBySize.seasons && this.$store.state.megaSearchDataBySize.seasons.length>0) {
        this.$store.commit('increaseMegaSearchSizeStep')
      }
    },
    setItem(season) {
      var control = this.selected.find(select => select.name === season.name)
      if (control) {
        const index = this.selected.findIndex(obj => obj.name === season.name);
        const newData = [
          ...this.selected.slice(0, index),
          ...this.selected.slice(index + 1)
        ]
        this.selected = newData
        this.$store.commit('megaSearchSetSeasonForSize', this.selected)
      } else {
        this.selected.push({
          name: season.name,
          item: season
        })
        this.$store.commit('megaSearchSetSeasonForSize', this.selected)
      }
    },
    setAllItems() {
      var items = []
      for (let index = 0; index < this.seasons.length; index++) {
        const element = this.seasons[index];
        items.push({
          name: element.name,
          item: element
        })
      }
      this.selected = items
      this.$store.commit('megaSearchSetSeasonForSize', this.selected)
    },
  }
}
</script>

<style scoped>
.title {
  font-size: 12px;
  font-weight: 700;
  color: var(--dark-white);
}
.seasons {
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex-flow: row wrap;
  justify-content: left;
  width: 100%;
}

.season {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.season:hover .season-logo {
  border: 5px solid var(--servislet);
}

.season:hover .season-logo, .season:hover .season-name {
  color: var(--servislet);
}

.season-logo {
  background-color: var(--gray);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 900;
  border: 5px solid #252525;
  width: 6rem;
  height: 6rem;
}

.season-name {
  font-weight: 700;
  margin-top: 5px;
}

.img {
  filter: brightness(0) invert(1);
}

@media only screen and (max-width: 1024px) {
  .season-logo {
    width: 4rem;
    height: 4rem;
    padding: 35px;
  }

  .season-name {
    font-size: 13px;
  }

  .img {
    width: 30px;
  }
}

.season.active {
  color: var(--servislet);
}

.season.active .season-logo {
  border-color: var(--servislet);
}
</style>
