<template>
  <b-sidebar id="tire-mega-search-with-car" title="Araca Göre Arama" no-header shadow right backdrop text-variant="light" v-model="control" lazy>
    <div class="row mt-2">
      <div class="container max-width-1200">
        <div class="megasearch-actions">
          <span class="actions-back cursor-pointer" @click="back()">
            <img src="/img/icons/backbutton.svg" alt="Close" width="30">
          </span>
          <span class="actions-close cursor-pointer" @click="closeBar">
            <img src="/img/icons/closebutton.svg" alt="Close" width="20">
          </span>
        </div>
        <span class="steps mt-2">
          <div class="step-content" v-for="(step, key) in 4" :key="step.$index" @click="setStep(key)">
            <!-- <span class="step" :class="[key === steps ? 'active' : '', key < steps ? 'complated' : '']"></span>
            <span class="step-value font-servislet" v-if="steps[key]"><i class="fas fa-check-circle"></i> {{ steps.items[key].name.toString().toUpperCase() }}</span> -->
            <span class="step" :class="[key === steps ? 'active' : '', key < steps ? 'complated' : '']"></span>
            <span class="step-value font-servislet" v-if="megaSearchData.brand && step === 1"><i class="fas fa-check-circle"></i> {{ megaSearchData.brand.name }}</span>

            <span class="step-value font-servislet" v-if="megaSearchData.year && step === 2"><i class="fas fa-check-circle"></i> {{ megaSearchData.year }}</span>

            <span class="step-value font-servislet" v-if="megaSearchData.model && step === 3"><i class="fas fa-check-circle"></i> {{ megaSearchData.model.car_model_name }}</span>

            <span class="step-value font-servislet" v-if="megaSearchData.version && step === 4"><i class="fas fa-check-circle"></i> {{ megaSearchData.version.version_name_1 + ' ' + megaSearchData.version.version_name_2}}</span>
            
          </div>
        </span>
        <div class="content mt-5">
          <!-- {{ $store.state.megaSearchData }} -->
          <!-- <CarMegaSearchCity v-if="steps === 0" /> -->
          <CarMegaSearchBrand v-if="steps === 0" />
          <CarMegaSearchYear v-if="steps === 1" />
          <CarMegaSearchModel v-if="steps === 2" />
          <CarMegaSearchVersion v-if="steps === 3" />
        </div>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
// import CarMegaSearchCity from "@/components/megasearch/car/CarMegaSearchCity.vue";
import CarMegaSearchBrand from "@/components/megasearch/car/CarMegaSearchBrand.vue";
import CarMegaSearchModel from "@/components/megasearch/car/CarMegaSearchModel.vue";
import CarMegaSearchYear from "@/components/megasearch/car/CarMegaSearchYear.vue";
import CarMegaSearchVersion from "@/components/megasearch/car/CarMegaSearchVersion.vue";

export default {
  name: "TireMegaSearchWithCar",
  components: { CarMegaSearchVersion, CarMegaSearchYear, CarMegaSearchModel, CarMegaSearchBrand },
  data() {
    return {
      control: false
    }
  },
  computed: {
    steps() {
      return this.$store.state.megaSearchSteps.car
    },
    megaSearchData() {
      return this.$store.state.megaSearchData
    }
  },
  methods: {
    back() {
      var url = new URL(window.location.href);
      url.searchParams.delete('car_brand_id')
      if(this.steps !== 0) {
        this.$store.commit('decreaseMegaSearchCarStep')
      }
    },
    setStep(key) {
      if (key >= 0) {
        
      }
    },
    closeBar() {
      this.$root.$emit('bv::toggle::collapse', 'tire-mega-search-with-car')
    }
  }
}
</script>

<style>
#tire-mega-search-with-car {
  width: 100% !important;
}
</style>

<style scoped>
.steps {
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 5px;
  height: 28px;
}

.step-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  cursor: pointer;
}

.step {
  height: 5px;
  width: 100%;
  background-color: var(--light-gray);
}

.step.complated {
  background-color: var(--servislet);
}

.step.active {
  background-color: var(--light);
}

.step-value {
  font-size: 13px;
  font-weight: 700;
}

@media only screen and (max-width: 1024px) {
  .step-value {
    display: none;
  }
}

.megasearch-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

</style>