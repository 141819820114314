/* eslint-disable */
<template>
  <b-sidebar id="tire-mega-search-with-size" title="Ebata Göre Arama" no-header shadow right bg-variant="dark" text-variant="light" v-model="control">
    <div class="row mt-2">
      <div class="container max-width-1200">
        <div class="megasearch-actions">
          <span class="actions-back cursor-pointer"> <!-- @click="setStep(steps.active - 1)" -->
            <img src="/img/icons/backbutton.svg" @click="back()" alt="Close" width="30">
          </span>
          <span class="actions-close cursor-pointer" @click="closeBar">
            <img src="/img/icons/closebutton.svg" alt="Close" width="20">
          </span>
        </div>
        <span class="steps mt-2">
          <span class="step-content" v-for="(step, key) in 6" :key="step.$index" @click="setStep(key)">
            <span class="step" :class="[key === steps ? 'active' : '', key < steps ? 'complated' : '']"></span>
<!--            <span class="step-value font-servislet" v-if="steps[key]"><i class="fas fa-check-circle"></i> {{ steps.items[key].name.toString().toUpperCase() }}</span>-->
             <span class="step-value" v-if="megaSearchDataBySize.brands.length>0 && step === 1" :class=" megaSearchDataBySize.brands.length>0 && key < steps ? 'font-servislet' : ''"><i class="fas fa-check-circle"></i> {{ brands.length === megaSearchDataBySize.brands.length ? 'TÜMÜ' : (megaSearchDataBySize.brands.length > 0 ? megaSearchDataBySize.brands[0].name : '') }}</span>
             <span class="step-value" v-if="megaSearchDataBySize.seasons.length>0 && step === 2" :class=" megaSearchDataBySize.seasons.length>0 && key < steps ? 'font-servislet' : ''"><i class="fas fa-check-circle"></i> {{ seasons.length === megaSearchDataBySize.seasons.length ? 'TÜMÜ' : (megaSearchDataBySize.brands.length > 0 ? megaSearchDataBySize.seasons[0].name : '') }}</span>
             <span class="step-value" v-if="megaSearchDataBySize.width && step === 3" :class=" megaSearchDataBySize.width && key < steps ? 'font-servislet' : ''"><i class="fas fa-check-circle"></i> {{ megaSearchDataBySize.width ? megaSearchDataBySize.width : '' }}</span>
             <span class="step-value" v-if="megaSearchDataBySize.height && step === 4" :class=" megaSearchDataBySize.height && key < steps ? 'font-servislet' : ''"><i class="fas fa-check-circle"></i> {{ megaSearchDataBySize.height }}</span>
             <span class="step-value" v-if="megaSearchDataBySize.radius && step === 5" :class=" megaSearchDataBySize.radius && key < steps ? 'font-servislet' : ''"><i class="fas fa-check-circle"></i> {{ megaSearchDataBySize.radius }}</span>

          </span>
        </span>
        <div class="content mt-5">
          <!-- <size-mega-search-city-component v-if="steps.active === 0" /> -->
          <SizeMegaSearchBrandComponent v-if="steps === 0" />
          <SizeMegaSearchSeasonComponent v-if="steps === 1" />
          <SizeMegaSearchWidthComponent v-if="steps === 2" />
          <SizeMegaSearchHeightComponent v-if="steps === 3" />
          <SizeMegaSearchRadiusComponent v-if="steps === 4" />
          <SizeMegaSearcRunflatComponent v-if="steps === 5" />
        </div>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
// import SizeMegaSearchCityComponent from "@/components/megasearch/size/SizeMegaSearchCity";
import SizeMegaSearchBrandComponent from "@/components/megasearch/size/SizeMegaSearchBrand";
import SizeMegaSearchSeasonComponent from "@/components/megasearch/size/SizeMegaSearchSeason";
import SizeMegaSearchWidthComponent from "@/components/megasearch/size/SizeMegaSearchWidth";
import SizeMegaSearchHeightComponent from "@/components/megasearch/size/SizeMegaSearchHeight";
import SizeMegaSearchRadiusComponent from "@/components/megasearch/size/SizeMegaSearchRadius";
import SizeMegaSearcRunflatComponent from "@/components/megasearch/size/SizeMegaSearcRunflat";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "TireMegaSearchWithSizeComponent",
  components: {
    SizeMegaSearcRunflatComponent,
    SizeMegaSearchRadiusComponent,
    SizeMegaSearchHeightComponent,
    SizeMegaSearchWidthComponent,
    SizeMegaSearchSeasonComponent,
    SizeMegaSearchBrandComponent,
    // SizeMegaSearchCityComponent
  },
  computed: {
    ...mapGetters(['getSizeBrandList']),
    ...mapGetters(['getSizeSeasonList']),
    steps() {
      return this.$store.state.megaSearchSteps.size
    },
    megaSearchDataBySize(){
      return this.$store.state.megaSearchDataBySize
    },
    brands() {
      return this.getSizeBrandList
    },
    seasons(){
      return this.getSizeSeasonList
    }
    // city() {
    //   return this.$store.state.shared.city.selected
    // }
  },
  data() {
    return {
      control: false
    }
  },
  mounted() {
    this.listSizeBrands()
    this.listSizeSeasons()
  },
  methods: {
    ...mapActions(['listSizeBrands']),
    ...mapActions(['listSizeSeasons']),
    setStep(key) {
      if (key >= 0) {
        if (key < this.steps.active) {
          this.$store.commit('app/tire/megasearch/setSizeClearItems', key)
        }
      }
    },
    closeBar() {
      this.$root.$emit('bv::toggle::collapse', 'tire-mega-search-with-size')
    },
    back() {
      if(this.steps !== 0) {
        this.$store.commit('decreaseMegaSearchSizeStep')
      }
    }
  }
}
</script>

<style>
#tire-mega-search-with-size {
  width: 100% !important;
}
</style>

<style scoped>
.steps {
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 5px;
  height: 28px;
}

.step-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  cursor: pointer;
}

.step {
  height: 5px;
  width: 100%;
  background-color: var(--light-gray);
}

.step-content .complated {
  background-color: var(--servislet) !important;
}

.step-content .active {
  background-color: var(--light) !important;
}

.step-value {
  font-size: 13px;
  font-weight: 700;
}

@media only screen and (max-width: 1024px) {
  .step-value {
    display: none;
  }
}

.megasearch-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
</style>
