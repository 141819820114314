import Vue from "vue";
import axios from 'axios';

const TOKEN = localStorage.userData ? 'Bearer '+JSON.parse(localStorage.userData).access_token : ''

const API_BASE_URL = process.env.VUE_APP_URL+'/api/v1'
// process.env.VUE_APP
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: TOKEN
  }
});

Vue.prototype.$axios = axiosInstance;

export default axiosInstance;

axiosInstance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    localStorage.removeItem('userData')
    window.location.href = '/giris-yap'
  }
  return Promise.reject(error);
});
