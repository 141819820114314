<!-- eslint-disable -->
<template>
  <b-sidebar id="cart-sidebar" title="Sepetim" shadow right backdrop text-variant="light" class="rounded" no-header>
    <div class="sidebar-close-button" @click="closeBar">
      <img src="/img/icons/closebutton.svg" alt="Close" width="20">
    </div>
    <div class="row" v-if="getUserCartItems.mustPay"> <!-- v-if="cart.mustPay" -->
      <div class="col-12 cart-content" v-if="getUserCartItems.userCart.cartItems.length">
        <span class="sidebar-title ml-2">Sepet <span>({{ getUserCartItems.userCart.cartItems.length }} ürün)</span></span>
        <div class="items mt-3">
          <div class="neu-black p-3 m-2" v-for="(item, key) in getUserCartItems.userCart.cartItems" :key="item.$index">
            <div class="cart-item d-flex flex-column">
              <div class="item d-flex">
                <router-link :to="{ name: 'ProductPage', params: { brand: item.product.brand_name, slug: item.product.slug }}">
                    <img class="item-image" :src="item.product.asset.path" alt="" width="130" height="130" v-if="item.product.asset && item.product.asset.path">
                    <img class="item-image" src="@/assets/tire-no-img.png" alt="No Img" width="130" height="130" v-else>
                </router-link>
                <div class="item-content">
                  <router-link :to="{ name: 'ProductPage', params: { brand: item.product.brand_name, slug: item.product.slug }}">
                  <div class="d-flex justify-content-between">
                    <span class="product-title">{{ item.product.brand_name }} {{ item.product.name }} </span>
                  </div>
                  </router-link>
                  <span class="small"></span>
                  <div class="d-flex justify-content-between">
                    <div class="d-flex flex-column">
                      <span class="price mt-1">{{ formatPrice(item.price * item.quantity) }} <small>/ {{ item.quantity !== null ? item.quantity : 0  }} Adet</small></span>
                    </div>
                  </div>
                  <div class="action">
                    <!-- <product-count-white-component class="mt-2" :quantity="parseInt(item.quantity)" :piece="item.quantity" @update:piece="newValue => changeCount(newValue, key, item)" :max-value="8" /> -->
                    <i class="fa fa-trash font-danger mt-2" @click="deleteItem(item)"></i>
                  </div>
                </div>
              </div>
              <div class="services" v-if="item.services && item.services.length>0">
                <span class="service-title">EK SERVİSLER</span>
                <div class="service"> <!-- v-for="service in item.services" :key="service.$index" v-if="service.name" -->
                  <span>{{ 'service.name' }} x {{'service.quantity'}}</span>
                  <span><!-- v-if="service.price" -->{{ "$helpers.currenctFormat('tr', service.price * service.quantity)" }}</span>
                  <!-- <span v-else>Ücretsiz</span> -->
                </div>
              </div>
              <div class="info-card" v-if="item.promotions && item.promotions.length>0">
                <span class="service-title">PROMOSYON</span>
                <div class="info"> <!-- v-for="item in cart.promotions" -->
                  <span>{{ 'item.name' }} <span> <!--  v-if="item.quantity > 1" --> x {{ 'item.quantity'}}</span></span>
                  <span>Hediye</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="info-car neu-black m-2">
          <span>Aracım: </span>
          <div class="d-flex align-items-center">
            <span>{{ 'car.name' }}</span>
          </div>
        </div> -->
        <div class="d-flex flex-column justify-content-center mt-4"> <!-- v-if="cart.mustPay" -->
          <div class="cart-summary">
            <div class="summary-item">
              <span>Ürünler</span>
              <span>{{ formatPrice(getUserCartItems.productTotal) }}</span>
            </div>
            <div class="summary-item">
              <span>Servisler</span>
              <span>{{ formatPrice(getUserCartItems.serviceTotal) }}</span>
            </div>
            <div class="summary-item"> <!-- v-if="cart.campaignTotal" -->
              <span>Kampanya İndirimi</span>
              <span>{{ formatPrice(getUserCartItems.campaignTotal) }}</span>
            </div>
            <span class="hr"></span>
            <div class="summary-item">
              <span class="total">Toplam</span>
              <span class="total">{{ formatPrice(getUserCartItems.mustPay) }}</span>
            </div>
          </div>
          <button class="checkout" @click="goToCart"><span><i class="fa-solid fa-lock mr-1"></i> Alışverişi Tamamla </span> <span class="ml-2"><!-- v-if="showPrice === true" --></span></button>
        </div>
      </div>
      <div v-else class="col-12 cart-content">
        <div>
          <b-skeleton width="100%"/>
          <b-skeleton width="100%"/>
          <b-skeleton width="100%"/>
          <b-skeleton width="100%"/>
          <b-skeleton width="100%"/>
          <b-skeleton width="100%"/>
          <b-skeleton width="100%"/>
          <br>
          <b-skeleton width="100%"/>
          <b-skeleton width="100%"/>
          <b-skeleton width="100%"/>
          <b-skeleton width="100%"/>
          <b-skeleton width="100%"/>
        </div>
        <div>
          <b-skeleton width="100%"/>
          <b-skeleton width="100%"/>
          <b-skeleton width="100%"/>
          <b-skeleton width="100%"/>
        </div>
      </div>
    </div>
    <div class="mt-3" v-else>
      <span>Alışveriş sepetinizde ürün bulunmuyor.</span>
    </div>
  </b-sidebar>
</template>

<script>
// import ProductCountWhiteComponent from "@/components/shared/ProductCountWhite";
import { mapGetters, mapActions } from 'vuex';
import { EventBus } from '@/plugins/event-bus.js';

export default {
  name: "CartSidebar",
  // components: {ProductCountWhiteComponent},
  data() {
    return {
      productCount: [],
      showPrice: false,
    }
  },
  computed: {
    ...mapGetters(['getUserCartItems', 'selectedCity']),
  },
  mounted() {
    this.listUserCartItems()
  },
  created() {
    EventBus.$on('open-cart-sidebar', this.closeBar);
  },
  beforeDestroy() {
    EventBus.$off('open-cart-sidebar', this.closeBar);
  },
  methods: {
    ...mapActions(['listUserCartItems']),
    formatPrice(price) {
      if(price) {
        return price.toLocaleString('tr-TR', {
          style: 'currency',
          currency: 'TRY',
        });
      } else if (price === 0) {
        return '₺0,00'
      }
    },
    goToCart() {
      // this.$router.push(this.localePath({name: 'tire-cart'}))
      this.$router.push('/lastik/sepetim')
    },
    deleteItem(item) {
      this.$store.commit('deleteCard', item.id)
      EventBus.$emit('custom-event', item);
      this.listUserCartItems()
    },
    closeBar() {
      this.$root.$emit('bv::toggle::collapse', 'cart-sidebar')
    },
    changeCount(value, key, item) {
      this.productCount[key] = value
      this.$store.dispatch(`app/tire/cart/updateCard`, {
        branch_id: this.cart.userCart.workingBranch.id,
        count: this.productCount[key],
        question: true,
        item_id: item.id,
      })
    }
  },
}
</script>

<style scoped>

.cart-content{
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  justify-content: space-between;
}

.cart-item {
  display: flex;
  justify-content: space-between;
}

.items {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;
}
.items::-webkit-scrollbar {
  display: none;
}
.item-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.item-content a {
    color: #06a5d0;
    text-decoration: none;
}
.checkout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  background: linear-gradient(to left, black 50%, var(--servislet) 50%) right;
  background-size: 200%;
  transition: .5s ease-out;
  color: var(--light);
  border: none;
  border-radius: 5px;
  height: 50px;
  font-weight: bolder;
  background-position: right;
}
.checkout:hover {
  background-position: left;
}

.item-image {
  background-color: var(--dark-gray);
  border-radius: 5px;
  border: 1px solid black;
  margin-right: 10px;
  padding: 10px;
}

.trash {
  color: var(--danger);
  cursor: pointer;
  margin-left: 10px;
}

.price {
  font-weight: 800;
  font-size: 18px;
}

small {
  font-size: 13px;
}

.product-title {
  width: 253px;
  line-height: 1.4;
}

.product-counter {
  border: 1px solid var(--light);
}

.services {
  border-top: 1px dashed var(--gray);
  margin-top: 10px;
  padding: 10px 0;
}

.service-title {
  color: var(--servislet);
  font-size: 15px;
  font-weight: 800;
}

.service {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.service span {
  color: var(--dark-white);
  font-size: 13px;
}

.cart-summary {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 10px;
  padding: 15px;
}

.summary-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.summary-item:last-child {
  border-top: 1px dashed var(--light);
  padding-top: 10px;
}

.summary-item span:nth-child(2) {
  padding-bottom: 6px;
}
.summary-item:last-child span:nth-child(2) {
  color: var(--servislet);
  border-bottom: 1px solid var(--servislet);
}

.total {
  font-size: 25px;
  font-weight: 800;
}

.info-card {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
}

.info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.info-title {
  font-weight: 900;
}

.info-car {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  margin-top: 20px;
}

.tire-cart-car {
  background-color: var(--light);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 25px;
  margin-right: 10px;
}

.product-counter {
  width: 50%;
  height: 40px;
}

.action {
  display: flex;
  align-items: center;
  gap: 10px;
}

.fa-trash {
  cursor: pointer;
}

@media only screen and (max-width: 580px) {
  .item {
    flex-direction: column;
  }

  .sidebar-close-button {
    margin-bottom: 0;
  }
}
</style>
