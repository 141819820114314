import Vue from "vue";
// import i18n from './i18n'
import { BootstrapVue } from 'bootstrap-vue'
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)


// SSR Carousel
import SsrCarousel from 'vue-ssr-carousel'
import ssrCarouselCss from 'vue-ssr-carousel/index.css'
Vue.component('ssr-carousel', SsrCarousel, ssrCarouselCss)

// Content placeholder
import VueContentPlaceholders from 'vue-content-placeholders'
Vue.use(VueContentPlaceholders)
// ValidationProvider from Vee Validate
import { ValidationProvider, ValidationObserver ,localize} from 'vee-validate';
import '@/plugins/validations'
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
localize('tr')
// SweetAlert
import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

// V-mask
import VueMask from 'v-mask'
Vue.use(VueMask);

// Or as a directive-only
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

// Or only as a filter-only
import { VueMaskFilter } from 'v-mask'
Vue.filter('VMask', VueMaskFilter)
// V Click Outside
import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside)
