<template>
  <div class="container mt-180"> <!-- :class="show === true ? 'paddingtop' : 'mt-180'" -->

    <h1 class="text-center mt-5 welcome-title">Şirket araçlarınızın ihtiyaçlarına uygun ürün ve hizmetler!</h1>
    <div class="welcome-card neu mt-5">
      <div class="row welcome-select-row">
        <!-- <div class="col-sm-12 col-md-12 col-lg-4 divider">
          <div class="welcome-select-card">
            <div class="select-card" v-b-toggle.city-sidebar>
              <span v-if="Object.keys(city).length == 0">{{ $t('choose_city')}}</span>
              <span v-else>{{ city.name }}</span>
              <img src="@/assets/icons/arrow-right-light.svg" alt="Arrow-Right">
            </div>
          </div>
        </div> -->
        <div class="col-sm-12 col-md-12 col-lg-6 divider">
          <div class="welcome-select-card">
            <div class="select-card" @click="clearMegaSearchData()" v-b-toggle:tire-mega-search-with-car>
              <div class="d-flex align-items-center">
                <img src="@/assets/icons/car.svg" alt="Car" class="mr-2" width="45">
                <span>Araca Göre Ara</span>
              </div>
              <img src="@/assets/icons/arrow-right-light.svg" alt="Arrow-Right">
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
          <div class="welcome-select-card">
            <div class="select-card" @click="clearMegaSearchData()" v-b-toggle:tire-mega-search-with-size>
              <div class="d-flex align-items-center">
                <img src="@/assets/icons/size.svg" alt="Size" class="mr-2" width="33">
                <span>Ebata Göre Ara</span>
              </div>
              <img src="@/assets/icons/arrow-right-light.svg" alt="Arrow-Right">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TireHomeWelcomeComponent",
  data() {
    return {}
  },
  methods: {
    clearMegaSearchData() {
      this.$store.state.megaSearchData = {}
      this.$store.state.megaSearchSteps.car = 0
      this.$store.state.megaSearchSteps.size = 0
      this.$store.state.megaSearchDataBySize = {
        brands: [],
        seasons: [],
        width: '',
        height: '',
        radius: '',
        runflat: 0
      }
    }

  }
}
</script>

<style scoped>
.container {
  max-width: 900px !important;
}
.mt-180 {
    margin-top: 180px;
}
.welcome-title {
    font-size: 40px;
    line-height: 48px;
    font-weight: 900
}
.mr-2 {
    margin-right: .5rem;
}
.neu {
    background: #eaeaea;
    box-shadow: 5px 5px 8px #dcdcdc, -5px -5px 8px #f6f6f6;
    border-radius: 15px;
}
.welcome-select-card {
  padding: 5px 0;
}
.welcome-select-row {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.select-card {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  background-color: #DEDEDE;
  border-radius: 8px;
  cursor: pointer;
  height: 60px;
  align-items: center;
  transition: .3s;
}
.select-card span {
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
  margin-right: 20px;
}
.divider {
  border-right: 1px solid #1c1c1c;
}
.select-card:hover .circle {
  border: 2px solid #f4f4f4;
}

@media only screen and (max-width: 912px){
  .divider {
    border-right: none;
  }
}

@media only screen and (device-width: 767px), only screen and (max-width: 767px) {
  .select-card {
    padding: 10px 15px;
  }
  .select-card span {
    line-height: 18px;
    white-space: inherit;
    margin-right: unset;
  }
  .welcome-select-row {
    padding: 10px 10px;
  }
  .welcome-title {
    font-size: 35px !important;
    line-height: 43px !important;
  }
}
</style>
